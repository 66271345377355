import {Route, Routes} from "react-router-dom";
import React from "react";
import {AdminSubsidyTabs} from "./ui/AdminSubsidyTabs";
import {JuridicalPage} from "@admin/feature/subsidy/ui/page/JuridicalPage";
import {PhysicalPage} from "@admin/feature/subsidy/ui/page/PhysicalPage";

export function FeatureSubsidy() {

    return (
        <div className="flex flex-col">
            <AdminSubsidyTabs/>
            <div className="mt-2">
                <Routes>
                    <Route path="juridical/*" element={<JuridicalPage/>}/>
                    <Route path="physical/*" element={<PhysicalPage/>}/>
                    {/*<Route path="/" element={<SubsidyListPage/>}/>*/}

                    {/*<Route path="/question/list" element={<SubsidyQuestionListPage/>}/>*/}
                    {/*<Route path="/question/:id/edit" element={<AdminSubsidyQuestionScreen/>}/>*/}

                    {/*<Route path="/:id/edit" element={<FeatureSubsidy/>}/>*/}
                    {/*<Route path="/:id" element={<SubsidyIdPage/>}/>*/}
                    {/*<Route path="/add" element={<AdminSubsidyAddPage/>}/>*/}
                    {/*<Route path="/list/:page?" element={<SubsidyListPage/>}/>*/}

                    {/*<Route path="/info/*" element={<SubsidyInfoPage/>}/>*/}
                    {/*<Route path="/info/list/:page?" element={<AdminSubsidyInfoListPage/>}/>*/}
                    {/*<Route path="/info/add" element={<AdminSubsidyInfoAddPage/>}/>*/}
                    {/*<Route path="/info/:id" element={<AdminSubsidyInfoIdPage/>}/>*/}

                    {/*<Route path="/info/type" element={<AdminSubsidyInfoTypeListPage/>}/>*/}
                    {/*<Route path="/info/type/:id" element={<AdminSubsidyInfoTypeIdPage/>}/>*/}
                </Routes>
            </div>
        </div>
    );
}