import {UpdateSubsidyJuridicalRequest} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalRequest";

export class UpdateSubsidyJuridicalCommonRequest extends UpdateSubsidyJuridicalRequest {

    constructor(
        readonly id: number,
        readonly name: string,
        readonly is_active: boolean,
        readonly is_favorite: boolean,
    ) {
        super(id);
    }
}