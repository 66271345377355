import {UserModel} from "./model/UserModel";
import {DetailedHTMLProps, HTMLAttributes} from "react";
import {UserItem} from "./UserItem";

interface UserListProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    userList: UserModel[]
}

export function UserList(props: UserListProps) {

    const {userList, ...divProps} = props

    return (
        <div {...divProps}>
            {
                userList.map((user) => {
                    return <UserItem user={user}/>
                })
            }
        </div>
    );
}