import {useEffect, useState} from "react";
import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import {useAppContext} from "@app/context/AppContextProvider";
import {List} from "@core/list/List";
import {OkvedDetailModel} from "@admin/feature/subsidy/domain/model/OkvedDetailModel";
import {ProgressBar} from "@core/ui/progress/ProgressBar";
import {FormSelectTreeMultiple, Option} from "@core/form/select-tree-multiple/FormSelectTreeMultiple";
import {OkvedModel} from "@admin/feature/subsidy/domain/model/OkvedModel";
import {Form} from "@core/form/Form";
import {FormSubmit} from "@core/form/submit/FormSubmit";
import {Logger} from "@core/logger/Logger";
import {SubsidyAnswerVariantFormSchema} from "@admin/feature/subsidy/ui/form/model/SubsidyAnswerVariantFormSchema";
import {UpdateSubsidyJuridicalOkvedModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalOkvedModel";
import {SelectSubsidyOkvedFormSchema} from "@admin/feature/subsidy/ui/form/model/SelectSubsidyOkvedFormSchema";

interface FormSelectOkvedProps {
    id:number
    defValue?: List<OkvedModel>
}

export function FormChangeSubsidyOkved(props: FormSelectOkvedProps) {

    const interactor = SubsidyInteractorImpl.init()
    const context = useAppContext()

    const [loading, setLoading] = useState(true)
    const [okvedList, setOkvedList] = useState<Array<Option<OkvedDetailModel>>>([])

    function updateOkved() {
        interactor.getOkvedList()
            .then(it => setOkvedList(toArrayOption(it)))
            .catch(context.snackbar.showError)
            .finally(() => setLoading(false))
    }

    function toOption(model: OkvedDetailModel): Option<OkvedDetailModel> {
        return {
            value: model,
            name: model.name,
            key: model.id,
            children: toArrayOption(model.children),
            isChecked: false
        }
    }

    function toArrayOption(list: List<OkvedDetailModel>): Array<Option<OkvedDetailModel>> {
        return list.map(it => toOption(it))
    }

    function onUpdate(data: SelectSubsidyOkvedFormSchema) {
        const update = new UpdateSubsidyJuridicalOkvedModel(
            props.id,List.listOf(...data.okved.map(it=>it.id))
        )
        interactor.updateSubsidyJuridical(update)
            .then(it=>context.snackbar.show("Окведы обновлены"))
            .catch(error => context.snackbar.showError(error))
            .finally()
    }

    useEffect(() => {
        updateOkved()
    }, [props.defValue]);

    return (
        <Form<SelectSubsidyOkvedFormSchema> className="flex flex-col w-full gap-y-2 border rounded-lg shadow-lg p-2"
                   onSuccessSerialize={onUpdate}>
            <div className="w-full text-center">ОКВЭД</div>
            <div className="flex flex-col bg-white rounded-lg">
                {
                    loading ? <ProgressBar/>
                        :
                        <FormSelectTreeMultiple
                            options={okvedList}
                            defValue={props.defValue?.map(it => it.id)}
                            placeholder="Нажмите чтобы выбрать"
                            name="okved"
                        />
                }
            </div>

            <FormSubmit label={"Обновить"}/>
        </Form>
    )
}