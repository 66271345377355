import {AnswerModel} from "@admin/feature/subsidy/domain/model/AnswerModel";
import {List} from "@core/list/List";

export class QuestionModel {

    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly isMultiple: boolean,
        public readonly answer: List<AnswerModel>) {
    }
}