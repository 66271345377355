import {BaseClient} from "@core/network/builder/BaseClient";
import {UserDetailedNetworkModel} from "./model/UserDetailedNetworkModel";
import {NetworkError} from "@core/network/builder/response/NetworkError";
import {SignInResponse} from "./model/SignInResponse";
import {StringUtil} from "@core/util/StringUtil";
import {AuthClient} from "@anon/core/network/client/auth/AuthClient";
import {ExtPromise} from "@core/network/promise/ExtPromise";

export class AuthClientImpl extends BaseClient implements AuthClient {

    recoverySearchUser(
        phone: string,
    ): ExtPromise<void> {
        return this.post<void>('/auth/recovery/search-user', {'phone': phone})
    }

    signIn(
        login: string,
        password: string,
    ): ExtPromise<SignInResponse> {
        const body = StringUtil.isValidEmail(login) ? {'email': login, 'password': password} :
            StringUtil.isValidPhone(login) ? {'phone': login, 'password': password} :
                {'login': login, 'password': password}

        return this.post<SignInResponse>("/auth/sign-in", body)

    }

    signInToken(
        token: string,
        onSuccess: (response: UserDetailedNetworkModel) => void,
        onError: (error: NetworkError) => void
    ): ExtPromise<SignInResponse> {
        return this.post<SignInResponse>("/auth/sign-in/token")
    }
}