import {UpdateSubsidyJuridicalModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalModel";
import {List} from "@core/list/List";

export class UpdateSubsidyJuridicalOkvedModel extends UpdateSubsidyJuridicalModel {

    constructor(
        readonly id: number,
        readonly okved: List<string>,
    ) {
        super(id);
    }
}