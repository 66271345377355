import {List} from "@core/list/List";
import {QuestionModel} from "@admin/feature/subsidy/domain/model/QuestionModel";

export class AnswerVariantModel{

    constructor(
        public readonly id: number,
        public readonly question: List<QuestionModel>
    ) {
    }
}