export abstract class BaseCookieStorage {

    protected abstract storage_name: string;
    protected abstract keys: Array<string>;

    setItem(key: string, value: string): void {
        document.cookie = `${this.buildKey(key)}=${encodeURIComponent(value)};path=/;`;
    }

    getItem(key: string): string | null {
        const cookies = document.cookie.split('; ').reduce((prev, current) => {
            const [name, value] = current.split('=');
            prev[name] = decodeURIComponent(value);
            return prev;
        }, {} as Record<string, string>);

        return cookies[this.buildKey(key)] || null;
    }

    clearItem(key: string): void {
        document.cookie = `${this.buildKey(key)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    clearAll(): void {
        const cookies = document.cookie.split('; ').map(cookie => cookie.split('=')[0]);
        for (const key of cookies) {
            document.cookie = `${key}=; expires=` + new Date(0).toUTCString() + `; path=/;`;
        }
    }

    buildKey(key: string): string {
        return "SOCIAL_SERVICE_" + this.storage_name + "_" + key;
    }
}