export function FeaturePrivacyPolicy() {

    return (
        <div className="absolute h-full w-full overflow-auto bg-white p-4">
            <div className="flex flex-col gap-2">
                <h1 className="text-center">Пользовательское соглашение мобильного приложения</h1>
                <h1 className="text-center">«СоцУслуги»</h1>
                <h1 className="text-left">Республика Башкортостан, город Уфа</h1>
                <h1 className="text-left">Дата публикации: 19 марта 2021 г.</h1>
                <text className="indent-8 mt-6">Пользовательское соглашение устанавливает требования, условия и правила
                    использования любым лицом мобильного приложения «СоцУслуги» и его возможностей и
                    не может рассматриваться как отказ правообладателя от своих прав на интеллектуальную
                    собственность. Заключение настоящего соглашения на изложенных в нем условиях
                    (присоединение) производится путем нажатия на соответствующую галочку в
                    пользовательском интерфейсе мобильного приложения «СоцУслуги» в процессе
                    регистрации, что означает ознакомление и полное безоговорочное принятие
                    пользователем условий, изложенных в настоящем документе, и создает между
                    пользователем и «СоцУслуги» договор на приведенных условиях в соответствии с
                    положениями ст. 437 и ст. 438 Гражданского кодекса Российской Федерации.
                    Использование мобильного приложения «СоцУслуги» допустимо исключительно на
                    условиях настоящего соглашения. Если пользователь не принимает условия
                    пользовательского соглашения в полном объёме, он не имеет права использовать
                    мобильное приложение в каких-либо целях.
                </text>
                <h1 className="text-center font-bold">1. Термины и определения</h1>
                <ul className="flex flex-col gap-2">
                    <li>1.1. Используемые в настоящем пользовательском соглашении слова и выражения имеют
                        следующие значения, если иное прямо не определено далее по тексту:
                    </li>
                    <ul className="flex flex-col gap-2">
                        <li>а) Пользовательское соглашение мобильного приложения «СоцУслуги» – настоящий
                            документ со всеми дополнениями, изменениями и приложениями;
                        </li>
                        <li>b) Правообладатель (так же Оператор) - Общество с ограниченной ответственностью
                            «АПЗ Новая Жизнь Регионы», ИНН 7203484046, КПП 720301001, ОГРН 1197232017872
                            (далее-Правообладатель), является правообладателем исключительного права на
                            мобильное приложение «СоцУслуги»;
                        </li>
                        <li>c) Мобильное приложение «СоцУслуги» - специально разработанное для мобильных
                            устройств программное обеспечение, принадлежащее Правообладателю (далее - «ПО» или
                            «Приложение»), экземпляр которого правомерно загружается Пользователями на
                            мобильные устройства. Приложение предоставляет Пользователям следующие
                            функциональные возможности:
                        </li>
                        <ul className="flex flex-col gap-2 list-disc p-6">
                            <li>определение видов услуг, предоставляемых Приложением;</li>
                            <li>построение маршрута по заданному Пользователем направлению в режиме диалога
                                c чат-ботом «СоцУслуги» и со специалистом юридической поддержки;
                            </li>
                            <li>предоставление Пользователю возможности перехода на сайты партнеров
                                «СоцУслуги посредством клика соответствующей гиперссылки для осуществления
                                покупки (оформления и оплаты) соответствующей услуги;
                            </li>
                            <li>предоставление на основании запросов Пользователя на платной основе
                                телефонной и текстовой консультации в области права со специалистом
                                юридической поддержки;
                            </li>
                        </ul>
                        <li>d) Специалист юридической поддержки – партнер Оператора, независимое физическое
                            лицо получившее юридическое образование, высшее и (или) среднее, оказывающее
                            юридические услуги посредством приложения «СоцУслуги»;
                        </li>
                        <li>e) Пользователь - лицо, заключившее настоящее Соглашение с правообладателем в своем
                            или в интересе третьего лица, фактически использующее Приложение. Заключая
                            Соглашение от имени третьих лиц, Пользователь гарантирует, что обладает всеми
                            полномочиями по предоставлению их интересов. В случае, если такие полномочия у
                            Пользователя отсутствуют, то считается, что Пользователь заключил Соглашение от
                            своего имени.
                        </li>
                        <li>f) ЮKassa (так же Партнер) - платежная система https://yookassa.ru, партнер Оператора,
                            обеспечивающая безопасные сделки между Пользователем и Оператором, позволяющая
                            Пользователю осуществлять расчеты со специалистами юридической поддержки при
                            использовании Приложения. Для использования ЮKassa, Пользователь обязан принять
                            все необходимые пользовательские соглашения, договор-оферту ЮKassa, и иные
                            документы ЮKassa, на основании которых осуществляется работа с ЮKassa.
                        </li>
                    </ul>
                    <li>1.2. В Соглашении могут быть использованы термины, не определенные выше. В этом
                        случае толкование такого термина производится в соответствии с действующим
                        законодательством Российской Федерации.
                    </li>
                </ul>
                <h1 className="text-center font-bold">2. Общие положения</h1>
                <ul className="flex flex-col gap-2">
                    <li>2.1. Соглашение заключено между Пользователем и Правообладателем и устанавливает
                        условия и правила использования Пользователем Приложения. Все существующие на
                        данный момент функции Приложения, а также любое их развитие и/или добавление
                        новых, и/или удаление, и/или отключение является предметом Соглашения.
                    </li>
                    <li>2.2. Использование Приложения с нарушением или невыполнением какого-либо из
                        условий, требований, правил Соглашения запрещено. Пользователь должен немедленно
                        удалить Приложение, в случае несогласия с положениями настоящего Соглашения. В
                        противном случае Пользователь считается безусловно согласным с требованиями,
                        правилами, условиями Соглашения.
                    </li>
                    <li>2.3. Используя Приложение, Пользователь подтверждает, что он полностью прочитал,
                        понял, согласен и принимает условия Соглашения с учетом возможных изменений,
                        обязуется его соблюдать и нести ответственность за его нарушение. В случае несогласия с
                        условиями Соглашения, Пользователь должен немедленно прекратить использование
                        Приложения.
                    </li>
                    <li>2.4. Помимо Соглашения права и обязанности Пользователя регламентированы иными
                        документами, в том числе любыми согласиями, предоставляемыми Пользователем, а
                        также Политикой конфиденциальности и Политикой обработки Персональных данных
                        Правообладателем, договором оказания юридических услуг, являющимся публичной
                        офертой, опубликованных в разделе Приложения «Правовые документы», являющихся
                        неотъемлемой частью Соглашения и входящих в его состав путем отсылки. В случае
                        противоречия между положениями Соглашения и такими специальными документами,
                        применению подлежат последние. Принимая условия настоящего Соглашения,
                        Пользователь выражает свое согласие с Политикой обработки персональных данных,
                        Политикой конфиденциальности и с условиями договора об оказании юридических услуг.
                    </li>
                </ul>
                <h1 className="text-center font-bold">3. Лицензионное соглашение и права на интеллектуальную
                    собственность</h1>
                <ul className="flex flex-col gap-2">
                    <li>3.1. В рамках Соглашения Правообладатель безвозмездно, на условиях простой
                        (неисключительной) лицензии, предоставляет Пользователю непередаваемое право
                        использования Приложения на территории всех стран мира на условиях «как есть»
                        следующими способами:
                    </li>
                    <li>3.1.1. Использовать Приложение в соответствии с его прямым функциональным
                        назначением (использовать поддерживаемые в Приложении функциональные
                        возможности, службы, услуги доступные Пользователям), в целях чего произвести его
                        копирование и установку (воспроизведение) на мобильное (-ые) устройство (-ва)
                        Пользователя. Пользователь вправе произвести установку Приложения на неограниченное
                        число мобильных устройств, при условии полного сохранения Приложения в виде,
                        предоставленном в соответствующем магазине приложений (Google Play или HUAWEI
                        AppGallery).
                    </li>
                    <li>При установке на мобильное устройство каждой копии Приложения присваивается
                        индивидуальный номер, который автоматически сообщается Правообладателю.
                    </li>
                    <li>3.1.2. Получать доступ к установке и использованию новых версий Приложения.</li>
                    <li>3.2. Работа с некоторыми функциональными возможностями Приложения возможна
                        только при наличии доступа в сеть Интернет. Пользователь самостоятельно и за свой счет
                        обеспечивает необходимый доступ в сеть Интернет.
                    </li>
                    <li>3.3. Срок действия лицензии равен сроку действия Соглашения, при прекращении
                        действия Соглашения Пользователь утрачивает право использования Приложения.
                    </li>
                    <li>3.4. Пользователю запрещается:
                        <ul className="flex flex-col gap-2 list-disc p-6">
                            <li>вмешиваться в работу Приложения способами, следствием использования которых
                                может быть нарушение его работоспособности, в том числе формировать запросы
                                иначе как через пользовательский интерфейс;
                            </li>
                            <li>использовать любые не авторизованные программы или приложения для
                                взаимодействия с Приложением;
                            </li>
                            <li>осуществлять модификацию, усовершенствование, перевод на другие языки,
                                декомпилирование, дизассемблирование, декодирование, эмуляцию, нарушение
                                целостности, восстановление исходного кода Приложения или каких-либо его
                                частей, производить иные действия с объектным кодом Приложения, имеющие
                                целью получение информации о реализации алгоритмов, используемых
                                Приложением, создавать производные произведения с использованием Приложения,
                                а также осуществлять иное использование Приложения, без письменного согласия
                                Правообладателя;
                            </li>
                            <li>использовать Приложение в незаконных целях.</li>
                        </ul>
                    </li>
                    <li>3.5. Правообладатель вправе в любое время по своему усмотрению без уведомления
                        Пользователя модифицировать Приложение, изменять его функционал, прекратить
                        поддержку работоспособности Приложения, а также без объяснения причин отказать
                        Пользователю в доступе к Приложению. Отказ в доступе к Приложению может быть
                        осуществлен Правообладателем в том числе и по причине нарушения Пользователем
                        Соглашения.
                    </li>
                    <li>3.6. Все материалы, опубликованные в Приложении, включая любую документацию,
                        тексты, данные (включая наличие услуг, цены, изображения, графики, дизайн, базы
                        данных, торговые марки, фирменные наименования, товарные знаки и/или логотипы и
                        иные средства идентификации и т.п.), охраняются авторским правами, принадлежат
                        Правообладателю или предоставлены ему правообладателями на основании
                        соответствующих соглашений.
                    </li>
                    <li>3.7. Использование указанных в п.3.6 объектов интеллектуальной собственности без
                        специального письменного согласия Правообладателя возможно только в рамках
                        функционала, предлагаемого Приложением для личного некоммерческого использования,
                        при условии сохранения всех знаков охраны авторского права, смежных прав, товарных
                        знаков, других уведомлений об авторстве, сохранении имени (или псевдонима)
                        автора/наименования правообладателя в неизменном виде, сохранении соответствующего
                        объекта в неизменном виде. Пользователь соглашается, что использование Приложения не
                        предоставляет ему никаких прав в отношение указанных объектов интеллектуальной
                        собственности, за исключением прав, явно указанных в Соглашении. Использование
                        Приложения не в коей мере не влечет у Пользователя возникновения прав на логотипы
                        и/или товарные знаки, в том числе являющихся частями оцифрованных изображений.
                    </li>
                    <li>3.8. Пользователь не вправе изменять наименование Приложения, изменять и/или удалять
                        знак охраны авторского права или иные указания на «СоцУслуги».
                    </li>
                    <li>3.9. Информация, доступ к которой предоставляет Приложение, включая сведения,
                        предоставленные в ходе проведения консультаций, ход действий, полученный от
                        специалистов юридической поддержки и т.д. (далее — «Данные»), предназначена
                        исключительно для личного использования Пользователем в рамках функциональных
                        возможностей Приложения. При использовании Данных Пользователь обязан соблюдать
                        условия настоящего Соглашения. При этом любое извлечение, копирование,
                        воспроизведение, переработка, распространение Данных, их размещение в свободном
                        доступе (опубликование) в сети Интернет, любое использование Данных в средствах
                        массовой информации и любое иное использование, явно неразрешенное настоящим
                        Соглашением или законодательством РФ, выходящее за рамки функциональных
                        возможностей Приложения без предварительного письменного разрешения
                        Правообладателя запрещается.
                    </li>
                    <li>3.10. Пользователь не имеет право воспроизводить и распространять Приложение в
                        коммерческих целях (в том числе за плату), в том числе в составе сборников программных
                        продуктов, без письменного согласия Правообладателя.
                    </li>
                    <li>3.11. Пользователь не имеет права распространять Приложение в виде, отличном от того,
                        в котором он его получил, без письменного согласия Правообладателя.
                    </li>
                </ul>
                <h1 className="text-center font-bold">4. Платежи, расчёты при использовании Приложения</h1>
                <ul className="flex flex-col gap-2">
                    <li>4.1. Все оплаты при использовании Приложения совершаются Пользователем
                        посредством ЮKassa в соответствии с правилами, условиями и комиссиями,
                        установленными ЮKassa.
                        Пользователю необходимо принять условия договора-оферты ЮKassa её
                        пользовательского соглашения и иных документов, регулирующих использование
                        ЮKassa.
                        Все оплаты осуществляются с использованием сервиса «Быстрый платеж через ЮMoney»
                        https://yoomoney.ru/pay/page?id=526623.
                    </li>
                    <li>4.2. Принимая условия настоящего Соглашения, Пользователь соглашается с тем, что
                        Оператор не оказывает Пользователю никаких возмездных услуг и не осуществляет прием
                        платежей.
                    </li>
                    <li>4.3. Оператор не несёт ответственности за любые суммы комиссий, которые могут
                        взиматься ЮKassa при перечислении или получении любых платежей в рамках
                        Приложения.
                        Пользователь соглашается с вышеуказанным условием, и самостоятельно несёт риски,
                        связанные с выбранным им способом оплаты услуг специалистов юридической
                        поддержки.
                    </li>
                    <li>4.4. Оператор обеспечивает размещение в Приложении платежной страницы для
                        осуществления Пользователем оплаты посредством ЮKassa, для внесения Пользователем
                        необходимых реквизитов оплаты. При этом Пользователь соглашается с тем, что
                        Оператор не участвует в процессе осуществления платежей, не является платежной
                        системой и не осуществляет процессинг платежей.
                    </li>
                </ul>
                <h1 className="text-center font-bold">5. Правила использования Приложения</h1>
                <ul className="flex flex-col gap-2">
                    <li>5.1. При использовании Приложения Пользователь обязуется:
                        <ul className="flex flex-col gap-2 list-disc p-6">
                            <li>Соблюдать положения действующего законодательства Российской Федерации,
                                Соглашения и иных документов, регулирующих порядок использования Приложения;
                            </li>
                            <li>Предоставлять верную, полную и актуальную информацию о себе и о лицах, от имени
                                которых такой Пользователь использует Приложение, а также документы,
                                подтверждающие достоверность такой информации. Пользователь принимает на себя
                                все возможные риски, связанные с его действиями по допущению ошибок,
                                неточностей в предоставленной им информации;
                            </li>
                            <li>При заказе услуги через Приложение оплачивать услуги Специалистов юридической
                                поддержки посредством ЮKassa, с учетом размера комиссии ЮKassa.
                            </li>
                            <li>Надлежащим образом охранять логин и пароль Пользователя, необходимые для
                                защиты доступа к Приложению от недобросовестного использования третьими
                                лицами, и незамедлительно уведомлять Оператора о любых случаях
                                недобросовестного использования посредством Обратной Связи;
                            </li>
                        </ul></li>
                    <li>5.2. Взаимодействие с Приложением, в том числе формирование запросов Пользователя
                        осуществляется исключительно посредством предоставляемого Пользователям
                        графического интерфейса Приложения и в соответствии с функциональными
                        возможностями Приложения. Предоставляемые в Приложении ссылки на любой сайт
                        Партнеров Правообладателя, продукт, услугу, любую информацию коммерческого или
                        некоммерческого характера, не является одобрением или рекомендацией данных
                        продуктов (услуг, деятельности, лиц) со стороны Правообладателя.
                    </li>
                    <li>5.3. Правообладатель не несет ответственности за финансовые и любые другие операции,
                        совершаемые на веб-сайтах Партнеров Правообладателя или с использованием таких, а
                        также за любые последствия приобретения Пользователем на стороне Партнеров.
                    </li>
                    <li>5.4. В целях пользования Приложением Пользователь подтверждает, что:</li>
                    <li>5.4.1 Пользователь дееспособен, правоспособен, имеет законное право вступать в
                        договорные отношения с Правообладателем и будет использовать Приложение в
                        соответствии с Соглашением;
                    </li>
                    <li>5.4.2. Вся предоставляемая Пользователем информация является правдивой, точной,
                        актуальной и полной.
                    </li>
                    <li>5.5. Пользователь подтверждает и выражает свое согласие с тем, что Приложение целиком
                        или часть его функционала в любой момент могут быть недоступны по техническим
                        причинам. «СоцУслуги» оставляет за собой право проводить необходимые
                        профилактические или иные технические работы с временным приостановлением работы
                        Приложения в любое время по своему усмотрению без предварительного уведомления
                        Пользователей.
                    </li>
                    <li>5.6. Правообладатель вправе отправлять своим пользователям информационные и
                        рекламные сообщения. Используя Приложение, Пользователь дает согласие на получение
                        на электронную почту Пользователя, сообщений информационного и рекламного
                        характера. Пользователь вправе отказаться от получения рассылки перейдя по
                        соответствующей ссылке, имеющейся в каждом письме.
                    </li>
                    <li>5.7. Правообладатель вправе осуществлять сбор мнений и отзывов Пользователей по
                        различным вопросам, предлагая пользователям пройти соответствующие опросы в
                        Приложении. Собранные мнения и отзывы могут быть использованы для формирования
                        статистических данных, которые могут быть использованы для улучшения качества
                        работы Приложения.
                    </li>
                    <li>5.8. Пользователь осознает и соглашается, что в Приложении может отображаться
                        реклама, и что наличие данной рекламы является необходимым условием использования
                        Приложения.
                    </li>
                </ul>
                <h1 className="text-center font-bold">6. Персональные данные</h1>
                <ul className="flex flex-col gap-2">
                    <li>6.1. Принимая условия настоящего Соглашения, Пользователь выражает согласие и
                        разрешает Правообладателю обрабатывать свои персональные данные, необходимые для
                        выполнения юридических услуг Специалистами юридической поддержки, в том числе для
                        разрешения возможных претензий и споров, связанных с исполнением настоящего
                        Соглашения.
                    </li>
                    <li>6.2. Пользователь согласен, что обработка персональных данных может быть
                        осуществлена с помощью автоматизированных систем управления базами данных, а также
                        иных программных средств следующими способами: сбор, систематизация, накопление,
                        хранение, уточнение (обновление, изменение), использование, обезличивание,
                        уничтожение персональных данных, передача персональных данных третьим лицам на
                        территории Российской Федерации, а именно оплату услуг, указанных Пользователем в
                        ООО НКО «Яндекс. Деньги» с целью осуществления оплаты с позволением дальнейшей
                        обработки всеми вышеперечисленными в настоящем пункте способами.
                    </li>
                    <li>6.3. Пользователь понимает и соглашается с тем, что Правообладатель может
                        предоставить персональные данные и иную обрабатываемую информацию Пользователя
                        уполномоченным государственным органам власти Российской Федерации по их запросу,
                        в том случае, если такая обязанность возникнет в связи с положениями действующего
                        законодательства Российской Федерации или если это станет необходимо для
                        недопущения, выявления незаконной или предположительно незаконной деятельности
                        либо для судебного преследования соответствующих лиц, для предотвращения
                        причинения ущерба или в ответ на судебный иск.
                    </li>
                    <li>6.4. Правообладатель не несет ответственность за сохранность персональных данных на
                        мобильном устройстве Пользователя. Пользователь несет полную ответственность в
                        случае доступа к мобильному устройству третьих лиц и обязуется принимать надлежащие
                        меры для обеспечения сохранности его мобильного устройства. В случае неправомерного
                        доступа третьих лиц к мобильному устройству Пользователя или его утрате.
                    </li>
                    <li>6.5. Принимая условия настоящего Соглашения, Пользователь дает согласие на передачу
                        информации, включая согласие на обработку, передачу, хранение своих персональных
                        данных всеми лицами, предусмотренными настоящим Соглашением. Пользователь
                        подтверждает, что осознает риски передачи сведений, включая персональные данные
                        через сеть интернет. Любая передача данных осуществляется на собственный страх и риск
                        Пользователя. Правообладатель не обеспечивает защиту данных каналов и не несет
                        ответственность перед Пользователем за потерю данных или получение к ним
                        несанкционированного доступа третьими лицами и не принимает на себя обязательства по
                        возмещению любых убытков Пользователя, которые могут возникнуть в связи с потерей
                        данных или получением несанкционированного доступа к ним третьими лицами, если
                        такой доступ происходит в момент передачи через сеть Интернет. Ответственность
                        Правообладателя за обеспечение сохранности данных ограничивается предотвращением
                        несанкционированного доступа в момент нахождения данных на серверах
                        Правообладателя.
                    </li>
                    <li>6.6. Ответственность за сохранность персональных данных на мобильном устройстве и
                        недоступность персональных данных для третьих лиц, несет Пользователь.
                        Правообладатель не несет ответственности перед Пользователем за любые убытки,
                        понесенные Пользователем в результате неправомерных действий третьих лиц, в том
                        числе, связанных с неправомерным доступом к мобильному устройству Пользователя, на
                        котором установлено ПО.
                    </li>
                    <li>6.7. Приложение использует сервисы веб-аналитики:
                        <ul className="flex flex-col gap-2 list-disc p-6">
                            <li>Яндекс.Метрика и Яндекс.Метрика для приложений, предоставляемые компанией
                                ООО «ЯНДЕКС», 119021, Россия, Москва, ул. Л. Толстого, 16 (далее - Яндекс).
                            </li>
                            <li>Google Analytics, предоставляемый компанией Google LLC, зарегистрированной по
                                адресу: 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (далее -
                                Google). Сервисы Яндекс.Метрика, Яндекс.Метрика для приложений и Google
                                Analytics используют технологию «cookie» - небольшие текстовые файлы,
                                размещаемые на мобильном устройстве Пользователя с целью анализа его
                                пользовательской активности. Сервисы веб аналитики обрабатывают эту информацию
                                в порядке, установленном в условиях использования сервиса Яндекс.Метрика,
                                Яндекс.Метрика для приложений и Google Analytics. Используя Приложение,
                                Пользователь соглашается на обработку данных о нем Яндекс и Google в порядке и
                                целях, установленном в условиях использования сервисов Яндекс.Метрика,
                                Яндекс.Метрика для приложений и Google Analytics.
                            </li>
                        </ul>
                    </li>
                    <li>6.8. Принимая условия настоящего Соглашения, Пользователь дает согласие
                        Правообладателю на осуществление автоматизированной обработки с совершением
                        следующих действий: сбор, включая сбор и анализ посредством сервисов веб-аналитики
                        запись, систематизацию, накопление, хранение, использование, уничтожение, передачу,
                        (предоставление доступа) информации о мобильном устройстве Пользователя (включая
                        производителя, модель, тип и версию операционной системы, ID устройства, геопозицию
                        устройства Пользователя), версии ПО, статистики по посещению Приложения и
                        использования функций Приложения, а также иной технической информации.
                        Информация передается Правообладателю в автоматическом режиме и может быть
                        использована Правообладателем для целей улучшения качества работы Приложения.
                    </li>
                    <li>6.9. Настоящим Пользователь дает согласие Правообладателю на получение от
                        Правообладателя на номер мобильного телефона, указанные Пользователем электронных
                        сообщений маркетингового и рекламного характера касательно услуг, оказываемых
                        Правообладателем и партнерами Правообладателя, а также на отправку Пользователю
                        электронных сообщений с приглашением пройти опросы или принять участие в
                        соответствующих маркетинговых или рекламных мероприятиях. Пользователь понимает и
                        выражает свое согласие Правообладателю на сбор и обработку персональных данных,
                        предоставляемых Пользователем в ходе проведения опросов и иных маркетинговых и
                        рекламных мероприятиях для исследовательских, статистических целей, а также для
                        целей продвижения Приложения и расширения его функционала.
                    </li>
                    <li>6.10. Пользователь выражает свое согласие с тем, что сообщения рекламного и
                        маркетингового характера могут быть направлены Пользователю Правообладателем по
                        иным сетям электронной связи в форме электронных писем, сообщений (SMS-сообщений,
                        WhatsApp Messenger, Viber и т.д.) телефонных звонков и иных формах.
                    </li>
                    <li>6.11. Согласие Пользователя на обработку персональных данных, согласованными
                        Сторонами в Соглашении способами, включая согласие на передачу персональных
                        данных третьим лицам, действует до момента прекращения срока действия Соглашения
                        или до отзыва согласия Пользователем путем направления Правообладателю письменного
                        заявления о прекращении обработки его персональных данных.
                    </li>
                </ul>
                <h1 className="text-center font-bold">7. Ответственность</h1>
                <ul className="flex flex-col gap-2">
                    <li>7.1. Функционал Приложения предоставляется на условиях «как есть» (as is).
                        Правообладатель не предоставляет никаких гарантий в отношении безошибочной и
                        бесперебойной работы Приложения, отдельных его частей, компонентов или функций,
                        соответствия функционала Приложения конкретным целям и ожиданиям Пользователя.
                    </li>
                    <li>7.2. Правообладатель не несет ответственности за какие-либо прямые или косвенные
                        последствия какого-либо использования или невозможности использования Приложения
                        (включая использование предоставленных посредством Приложения данных) или его
                        отдельных функциональных возможностей и/или ущерб, причиненный Пользователю
                        и/или третьим сторонам в результате какого-либо использования, неиспользования или
                        невозможности использования Приложения (включая использование предоставленных
                        посредством Приложения данных) или отдельных его компонентов и/или функций, в том
                        числе из-за возможных ошибок или сбоев в работе Приложения.
                    </li>
                    <li>7.3. Правообладатель не несет ответственности за любые виды убытков, произошедшие
                        вследствие использования Пользователем Приложения или его отдельных
                        функциональных возможностей. При любых обстоятельствах ответственность
                        Правообладателя в соответствии со ст. 15 Гражданского кодекса Российской Федерации
                        ограничивается стоимостью услуг Правообладателя, но не более 5 000 (пять тысяч)
                        рублей и возлагается на Правообладателя при наличии в действиях «СоцУслуги» вины.
                    </li>
                    <li>7.4. Пользователь обязуется использовать Приложение в соответствии с законами той
                        страны, в которой он находится, и принимает на себя ответственность за нарушение
                        установленных национальным законодательством ограничений на использование
                        Приложения.
                    </li>
                    <li>7.5. Пользователь самостоятельно несет ответственность перед третьими лицами за свои
                        действия, связанные с использованием Приложения, в том числе, если такие действия
                        приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение
                        применимого законодательства при использовании Приложения.
                    </li>
                    <li>7.6. Правообладатель не принимает на себя никакой ответственности за настройки
                        Пользователя, все вопросы предоставления прав доступа к сети интернет, покупки и
                        наладки для этого соответствующего оборудования и программных продуктов решаются
                        Пользователем самостоятельно и не подпадают под действие Соглашения.
                        Правообладатель не несет перед Пользователем ответственности за качество и за любой
                        ущерб, причиненный Пользователю в результате использования им некачественных или
                        незащищенных компьютерных систем и каналов связи.
                    </li>
                    <li>7.7. Пользователь соглашается с тем, что Правообладатель не несет ответственности перед
                        Пользователем и третьими лицами за изменения, приостановление, и прекращение работы
                        Приложения.
                    </li>
                </ul>
                <h1 className="text-center font-bold">8. Заключительные положения</h1>
                <ul className="flex flex-col gap-2">
                    <li>8.1. Текст настоящего Соглашения со всеми приложениями, изменениями и дополнениями
                        к нему, размещен в соответствующем разделе Приложения. Указанный документ (в том
                        числе любые из его частей) могут быть изменены Правообладателем в одностороннем
                        порядке без какого-либо специального уведомления, новая редакция документа вступает в
                        силу с момента его размещения в соответствующем разделе Приложения, если иное не
                        предусмотрено новой редакцией документа.
                    </li>
                    <li>8.2. Пользователь самостоятельно контролирует изменения и в случае несогласия
                        прекращает использование Сервиса, что означает расторжение Соглашения
                        Пользователем. Продолжение использования Сервиса означает согласие Пользователя с
                        изменением условий Соглашения.
                    </li>
                    <li>8.3. Если какая-либо часть Соглашения будет признана недействительной, незаконной или
                        не имеющей законной силы, то действительность, законность и применимость остальных
                        положений остается в силе.
                    </li>
                    <li>8.4. Использование Приложения является неправомерным в любой юрисдикции, которая
                        не признает все условия Соглашения.
                    </li>
                    <li>8.5. Ввиду безвозмездности предоставления функциональных возможностей
                        Пользователю, которые не являются услугами, нормы о защите прав потребителей,
                        предусмотренные законодательством Российской Федерации не применимы к
                        отношениям между Пользователем и Правообладателем.
                    </li>
                    <li>8.6. Правообладатель оставляет за собой право на свое усмотрение ограничить доступ
                        Пользователя к Приложению (или к определенным функциям Приложения, если это
                        возможно технологически) или полностью заблокировать учетную запись Пользователя
                        при неоднократном нарушении условий Соглашения, либо применить к Пользователю
                        иные меры с целью соблюдения требований Законодательства.
                    </li>
                    <li>8.7. Правообладатель вправе в любое время расторгнуть Соглашение в одностороннем
                        порядке. Расторжение Соглашения Правообладателем производится без предварительного
                        уведомления Пользователя путем блокировки работы Приложения или реализуемого им
                        функционала.
                    </li>
                    <li>8.8. К Соглашению и всем отношениям, связанным с использованием Приложения,
                        подлежит применению Законодательство Российской Федерации. В случае возникновения
                        любых спорных ситуаций Пользователь перед обращением в судебные органы за
                        разрешением спора обязан предпринять все меры для досудебного урегулирования,
                        направив соответствующее письмо по адресу местонахождения Правообладателя, а также
                        его электронную копию по адресу: helplawyeronline@gmail.com. Срок рассмотрения и
                        ответа на претензию- 30 (тридцать) рабочих дней с момента ее получения
                        Правообладателем. Любые претензии или иски, вытекающие из Соглашения или
                        использования Сервиса, должны быть поданы и рассмотрены в суде по месту нахождения
                        Правообладателя, а именно в Ленинский районный суд г. Уфы Республики Башкортостан.
                    </li>
                    <li>8.9. Обращения, предложения и претензии Пользователей по поводу работы Приложения
                        могут быть направлены на электронный адрес Правообладателя
                        helplawyeronline@gmail.com Направляя письма на данный электронный адрес,
                        Пользователь подтверждает свое согласие на использование Правообладатель
                        электронного адреса, с которого было отправлено письмо для получения ответа от
                        Правообладателя на обращение Пользователя.
                    </li>
                    <li>8.10. Соглашение вступает в силу с момента его принятия Пользователем, посредством
                        проставления отметки о согласии с условиями Пользовательского соглашения в
                        соответствующей графе.
                    </li>
                </ul>
            </div>
        </div>
    )
        ;
}