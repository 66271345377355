import {API_KEY} from "../../../config.js";
import {BaseClient} from "../../builder/BaseClient";
import {UserToken} from "./model/UserToken";
import {UserDetailedNetworkModel} from "./model/UserDetailedNetworkModel";
import {NetworkError} from "../../builder/response/NetworkError";
import {SignInResponse} from "./model/SignInResponse";
import {isValidEmail, isValidPhone} from "../../../core/extension/Extensions";
import {Logger} from "../../../core/logger/Logger";

export class AuthClient extends BaseClient {
    constructor() {
        super(
            new Headers({
                'Host': 'https://www.sozuslugi.ru/api/v2',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${API_KEY}`,
            })
        );
    }

    recoverySearchUser(
        phone: string,
        onSuccess: (response: boolean) => void,
        onFail: (error: NetworkError) => void
    ) {
        this.post<boolean>('/auth/recovery/search-user', {'phone': phone})
            .catch(tr => {})

    }

    signIn(
        login: string,
        password: string,
        onSuccess: (response: SignInResponse) => void,
        onError: (error: NetworkError) => void
    ) {
        const body = isValidEmail(login) ? {'email': login, 'password': password} :
            isValidPhone(login) ? {'phone': login, 'password': password} :
                {'login': login, 'password': password}

        this.post<SignInResponse>("/auth/sign-in", body)
            .then(response => onSuccess(response))
            .catch(error => onError(error))
    }

    signInToken(
        token: string,
        onSuccess: (response: UserDetailedNetworkModel) => void,
        onError: (error: NetworkError) => void
    ) {
        this.post<UserDetailedNetworkModel>("/auth/sign-in/token")
            .then(response => onSuccess(response))
            .catch(error => onError(error))
    }
}