import {useEffect, useState} from "react";
import {AnswerModel} from "@admin/feature/subsidy/domain/model/AnswerModel";
import {QuestionModel} from "@admin/feature/subsidy/domain/model/QuestionModel";
import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import {cloneDeep} from "lodash";
import {useAppContext} from "@app/context/AppContextProvider";
import {ProgressBar} from "@core/ui/progress/ProgressBar";
import {FormFieldSet} from "@core/form/fieldset/FormFieldSet";
import {ButtonRemove} from "@core/ui/button/ButtonRemove";
import {ButtonPrimary} from "@core/ui/button/ButtonPrimary";
import {List} from "@core/list/List";
import {Form} from "@core/form/Form";
import {FormSubmit} from "@core/form/submit/FormSubmit";
import {list} from "postcss";
import {AnswerVariantModel} from "@admin/feature/subsidy/domain/model/AnswerVariantModel";
import {Logger} from "@core/logger/Logger";
import {SubsidyAnswerVariantFormSchema} from "@admin/feature/subsidy/ui/form/model/SubsidyAnswerVariantFormSchema";
import {
    UpdateSubsidyJuridicalAnswerVariantModel
} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalAnswerVariantModel";

interface VariantQuestion {
    isShow: boolean
    questions: Array<SelectQuestion>
    isAddQuestion: boolean
}

interface SelectQuestion {
    selectAr: Array<{ question: QuestionModel, isDisable: boolean }>
    select?: {
        question: QuestionModel,
        isAddAnswer: boolean,
        answers: Array<{ selectAnswer?: AnswerModel }>,
        answersAr: Array<{ answer: AnswerModel, isDisable: boolean }>
    }
}

interface FormSelectQuestionVariantProps {
    id: number
    defValue?: List<AnswerVariantModel>
}

export function FormChangeQuestionVariant(props: FormSelectQuestionVariantProps) {

    const context = useAppContext()
    const interactor = SubsidyInteractorImpl.init()

    const [variantQuestion, setVariantQuestion] = useState<Array<VariantQuestion>>([])
    const [totalQuestion, setTotalQuestion] = useState<Array<QuestionModel>>([])
    const [loading, setLoading] = useState(true)

    function addQuestion(index: number) {

        let newVariantQuestion = cloneDeep(variantQuestion)
        const questionArr = newVariantQuestion[index].questions.map(it => it.select?.question)

        newVariantQuestion[index].isAddQuestion = newVariantQuestion[index].questions.length + 1 != totalQuestion.length

        newVariantQuestion[index].questions =
            [...newVariantQuestion[index].questions, {
                selectAr: totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                    return {question: it, isDisable: questionArr.find(it1 => it1?.id === it.id) !== undefined}
                }),
                select: undefined,
            }]

        setVariantQuestion([...newVariantQuestion])
    }

    function addAnswer(indexVariant: number, index: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        newVariantQuestion[indexVariant].questions[index].select!!.answers =
            [...newVariantQuestion[indexVariant].questions[index].select!!.answers, {selectAnswer: undefined}]
        newVariantQuestion[indexVariant].questions[index].select!!.isAddAnswer =
            newVariantQuestion[indexVariant].questions[index].select!!.answersAr.length !==
            newVariantQuestion[indexVariant].questions[index].select!!.answers.length
        setVariantQuestion(newVariantQuestion)
    }

    function onChangeAnswer(answer: AnswerModel, indexVariant: number, indexQuestion: number, indexAnswer: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)

        newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answers[indexAnswer].selectAnswer = answer
        newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answersAr.find(it => it.answer.id == answer.id)!!.isDisable = true

        setVariantQuestion(newVariantQuestion)
    }

    function onChangeSelectQuestion(question: QuestionModel, indexVariant: number, index: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        newVariantQuestion[indexVariant].questions[index].select =
            {
                question: question,
                isAddAnswer: true,
                answers: [{selectAnswer: undefined}],
                answersAr: question.answer.map<{ answer: AnswerModel, isDisable: boolean }>(it => {
                    return {answer: it, isDisable: false}
                })

            }

        const questionArr = newVariantQuestion[indexVariant].questions.map(it => it.select?.question)

        newVariantQuestion[indexVariant].questions.map<SelectQuestion>(it => {
            it.selectAr = totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                return {question: it, isDisable: questionArr.find(it1 => it1?.id === it.id) !== undefined}
            })
            return it
        })

        setVariantQuestion(newVariantQuestion)
    }

    function addVariantQuestion() {
        let newVariantQuestion = cloneDeep(variantQuestion)
        setVariantQuestion([...newVariantQuestion,
            {
                isShow: true,
                questions: [{
                    selectAr: totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                        return {question: it, isDisable: false}
                    }),
                    select: undefined,
                }],
                isAddQuestion: true
            }])
    }

    function removeVariant(index: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        newVariantQuestion.splice(index, 1)
        setVariantQuestion(newVariantQuestion)
    }

    function removeQuestion(indexVariant: number, index: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        const idQuestionRemove = newVariantQuestion[indexVariant].questions[index].select!.question.id
        newVariantQuestion[indexVariant].questions.splice(index, 1)
        newVariantQuestion[indexVariant].isAddQuestion = true
        newVariantQuestion[indexVariant].questions.forEach(it => {
            it.selectAr.find(it => it.question.id == idQuestionRemove)!.isDisable = false
        })
        // newVariantQuestion[indexVariant].questions[1].selectAr.find(it=>it.question.id == idQuestionRemove)!.isDisable = false
        // const questionArr = newVariantQuestion[indexVariant].questions.map(it => it.select?.question)
        // newVariantQuestion[indexVariant].questions.map<SelectQuestion>(it => {
        //     it.selectAr = totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
        //         return {question: it, isDisable: questionArr.find(it1 => it1?.id === it.id) !== undefined}
        //     })
        //     return it
        // })

        // newVariantQuestion[indexVariant].questions[index].select!!.isAddAnswer =
        //     newVariantQuestion[indexVariant].questions[index].select!!.answersAr.length !==
        //     newVariantQuestion[indexVariant].questions[index].select!!.answers.length
        setVariantQuestion(newVariantQuestion)
    }

    function updateTotalQuestion(list: List<QuestionModel>) {
        setTotalQuestion(list)

    }

    function onUpdate(data: SubsidyAnswerVariantFormSchema) {

        Logger.debug(data)
        const update = new UpdateSubsidyJuridicalAnswerVariantModel(
            props.id, new List(...data.answer_variant.map(it => {
                return {
                    question: new List(...it.question.map(it => {
                        let ids: List<number> = new List<number>()
                        it.answer.forEach(it => ids.push(it.id))
                        return {id: it.id, answer: ids}
                    }))
                }
            }))
        )
        interactor.updateSubsidyJuridical(update)
            .then(() => context.snackbar.show("Список вопросов обновлен"))
            .catch((error) => context.snackbar.showError(error))
    }

    function onClickRemoveAnswer(indexVariant: number, indexQuestion: number, indexAnswer: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)

        const idAnswer = newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answers[indexAnswer].selectAnswer!.id
        newVariantQuestion[indexVariant].questions[indexQuestion].select!.answers.splice(indexAnswer, 1)
        newVariantQuestion[indexVariant].questions[indexQuestion].select!.answersAr.find(it => it.answer.id === idAnswer)!.isDisable = false
        newVariantQuestion[indexVariant].questions[indexQuestion].select!.isAddAnswer = true
        setVariantQuestion(newVariantQuestion)
    }

    function onClickRemoveQuestion(indexVariant: number, indexQuestion: number) {
        removeQuestion(indexVariant, indexQuestion)
    }

    function onClickRemoveVariant(indexVariant: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        newVariantQuestion.splice(indexVariant, 1)
        setVariantQuestion(newVariantQuestion)
    }

    useEffect(() => {
        interactor.getQuestionJuridicalList()
            .then(it => updateTotalQuestion(it))
            .catch(context.snackbar.showError)
            .finally(() => setLoading(false))
    }, []);

    useEffect(() => {
    }, [variantQuestion]);

    useEffect(() => {
        if (totalQuestion.length > 0) {

            let newVariantQuestion: Array<VariantQuestion> = []

            props.defValue?.forEach((it, indexVariant) => {
                // addAnswerVariant
                newVariantQuestion = [...newVariantQuestion, {
                    isShow: true,
                    questions: [],
                    isAddQuestion: true
                }]

                it.question.forEach((question, indexQuestion) => {
                    //addQuestion
                    const questionArr = newVariantQuestion[indexVariant].questions.map(it => it.select?.question)

                    newVariantQuestion[indexVariant].isAddQuestion = newVariantQuestion[indexVariant].questions.length + 1 != totalQuestion.length

                    newVariantQuestion[indexVariant].questions =
                        [...newVariantQuestion[indexVariant].questions, {
                            selectAr: totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                                return {
                                    question: it,
                                    isDisable: questionArr.find(it1 => it1?.id === it.id) !== undefined
                                }
                            }),
                            select: undefined,
                        }]

                    //changeQuestion
                    newVariantQuestion[indexVariant].questions[indexQuestion].select =
                        {
                            question: question,
                            isAddAnswer: true,
                            answers: [],
                            answersAr: totalQuestion.find(q => q.id === question.id)!!.answer.map(it => {
                                return {answer: it, isDisable: false}
                            })
                            // question.answer.map<{ answer: AnswerModel, isDisable: boolean }>(it => {
                            //     return {answer: it, isDisable: false}
                            // })

                        }

                    const questionArr1 = newVariantQuestion[indexVariant].questions.map(it => it.select?.question)

                    newVariantQuestion[indexVariant].questions.map<SelectQuestion>(it => {
                        it.selectAr = totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                            return {question: it, isDisable: questionArr1.find(it1 => it1?.id === it.id) !== undefined}
                        })
                        return it
                    })


                    question.answer.forEach((answer, indexAnswer) => {

                        //addAnswer
                        newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answers =
                            [...newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answers, {selectAnswer: answer}]
                        newVariantQuestion[indexVariant].questions[indexQuestion].select!!.isAddAnswer =
                            newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answersAr.length >
                            newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answers.length

                        //changeAnswer
                        newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answers[indexAnswer].selectAnswer = answer
                        newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answersAr.find(it => it.answer.id == answer.id)!!.isDisable = true

                    })

                })
            })
            setVariantQuestion([...newVariantQuestion])
        }
    }, [totalQuestion]);

    return (
        <Form<SubsidyAnswerVariantFormSchema> className="flex flex-col w-full gap-y-2 border rounded-lg shadow-lg p-2"
                                              onSuccessSerialize={onUpdate}>
            <div className="w-full text-center border-b">Вопросы и ответы</div>
            <div className="flex flex-col bg-white">
                {loading ? <div className="self-center"><ProgressBar/></div> :
                    <div className="mt-2">
                        <FormFieldSet className="flex flex-col gap-y-2"
                                      name="answer_variant[]">
                            {
                                variantQuestion.map((varQuestion, indexVariant) => {
                                    return (
                                        <div
                                            className="flex flex-row gap-y-2 bg-gray-100 rounded-lg p-2 justify-between"
                                            key={indexVariant}>

                                            <FormFieldSet className="flex flex-col"
                                                          name={`question[]`}>
                                                {varQuestion.questions.map((question, indexQuestion) => {
                                                    return (
                                                        <div className="flex flex-row gap-x-2"
                                                             key={indexQuestion}>
                                                            <div
                                                                className="flex flec-row bg-primary-200 p-2 gap-x-2 rounded-lg">
                                                                <select
                                                                    name="id"
                                                                    typeof="number"
                                                                    value={question.select?.question.id}
                                                                    onChange={event =>
                                                                        onChangeSelectQuestion(totalQuestion.find(it => event.currentTarget.value == it.id.toString())!!, indexVariant, indexQuestion)
                                                                    }
                                                                    defaultValue={-1}
                                                                    input-type="number"
                                                                >
                                                                    <option value="-1" key={-1} disabled>None
                                                                    </option>
                                                                    {
                                                                        question.selectAr.map(select => {
                                                                            return (
                                                                                <option key={select.question.id}
                                                                                        value={select.question.id}
                                                                                        disabled={select.isDisable}>{select.question.title}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                <ButtonRemove
                                                                    onClick={() => onClickRemoveQuestion(indexVariant, indexQuestion)}/>
                                                            </div>
                                                            <FormFieldSet className="flex flex-row gap-x-1"
                                                                          name="answer[]">
                                                                {
                                                                    question.select?.answers.map((it, index) => {
                                                                        return (
                                                                            <div className="flex flex-row">
                                                                                <select
                                                                                    key={index}
                                                                                    name="id"
                                                                                    value={it.selectAnswer?.id}
                                                                                    typeof="number"
                                                                                    input-type="number"
                                                                                    defaultValue={-1}
                                                                                    onChange={event => onChangeAnswer(
                                                                                        question.select!!.answersAr.find(it => event.currentTarget.value == it.answer.id.toString())!!.answer,
                                                                                        indexVariant,
                                                                                        indexQuestion,
                                                                                        index)}>
                                                                                    <option value="-1" key={-1}
                                                                                            disabled>None
                                                                                    </option>
                                                                                    {

                                                                                        question.select?.answersAr.map(it => {
                                                                                            return <option
                                                                                                key={it.answer.id}
                                                                                                value={it.answer.id}
                                                                                                disabled={it.isDisable}>{it.answer.title}</option>
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                                <ButtonRemove
                                                                                    onClick={() => onClickRemoveAnswer(indexVariant, indexQuestion, index)}/>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </FormFieldSet>
                                                            {
                                                                question.select?.isAddAnswer ?
                                                                    <div className="flex justify-center items-center bg-green-300 rounded-full w-6 h-6 cursor-pointer
                                            hover:bg-green-400
                                            active:bg-green-500"
                                                                         onClick={_ => addAnswer(indexVariant, indexQuestion)}>+</div>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                                {varQuestion.isAddQuestion ?
                                                    <ButtonPrimary className="flex p-2 w-full justify-start"
                                                                   onClick={() => addQuestion(indexVariant)}
                                                                   label="Добавить вопрос"/>
                                                    : <></>}
                                            </FormFieldSet>
                                            <ButtonRemove onClick={() => onClickRemoveVariant(indexVariant)}/>
                                        </div>
                                    )
                                })
                            }
                        </FormFieldSet>
                        <ButtonPrimary className="flex p-2 w-full justify-center" onClick={addVariantQuestion}
                                       label="Добавить вариант ответов"/>
                    </div>}
            </div>

            <FormSubmit label={"Обновить"}/>
        </Form>
    )
}