import {ReactNode} from "react";

type LayoutPageProps = {
    children?:ReactNode
}

export function LayoutPage(props:LayoutPageProps){

    return (
        <div className="absolute z-40 w-full h-full top-0 left-0 bg-white">{props.children}</div>
    )
}