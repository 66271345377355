import React, {useState} from "react";
import {Background} from "@anon/core/ui/background/Background";
import {Navigate, Route, Routes} from "react-router-dom";
import {FeatureAuth} from "./feature/auth/FeatureAuth";

export function AppAnon() {

    const [isWidthMore640, setIsWidthMore640] = useState(window.screen.width >= 640)

    window.addEventListener("resize", screenResize)

    function screenResize() {
        setIsWidthMore640(window.screen.width >= 640)
    }

    return (
        <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
            {isWidthMore640 ? <Background/> : null}
            <Routes>
                <Route path="auth/*" element={<FeatureAuth/>}/>
                <Route path="*" element={<Navigate to={"auth"}/>}/>
            </Routes>
        </div>
    );
}