import {List} from "@core/list/List";

export class OkvedDetailNetworkModel {

    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly children: List<OkvedDetailNetworkModel>
    ) {
    }
}