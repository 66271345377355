import React, {useEffect} from "react";
import {Button, Fab} from "@mui/material";
import {IconChecked} from "../icon/IconChecked";
import {
    useAdminSubsidyQuestionContext
} from "@admin/feature/subsidy/question/list/context/SubsidyQuestionContextProvider";

export interface ButtonFloatingProps {
    text: string
    onClick: () => void

    icon:JSX.Element
}

export function ButtonFloating(props: ButtonFloatingProps) {

    return (
        <div
            className="fixed bottom-12 right-16">
            <Fab color={"primary"}
                onClick={() => {
                    props.onClick()
                }}>
                {props.icon}
            </Fab>
        </div>
    );
}