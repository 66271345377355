import {CircularProgress} from "@mui/material";
import {
    useAdminSubsidyQuestionContext
} from "@admin/feature/subsidy/question/list/context/SubsidyQuestionContextProvider";
import {Logger} from "../../logger/Logger";

export function ProgressBar() {

    return (
        <CircularProgress/>
    );
}