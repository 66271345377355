import {List} from "@core/list/List";
import {SubsidyInfoModel} from "@admin/feature/subsidy/domain/model/SubsidyInfoModel";

export class SubsidyModel {

    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly isActive: boolean,
        public readonly isFavorite: boolean,
        public readonly info: List<SubsidyInfoModel>,
    ) {
    }
}