import {UpdateSubsidyJuridicalModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalModel";
import {List} from "@core/list/List";
import {InfoModel} from "@admin/feature/subsidy/domain/model/InfoModel";

export class UpdateSubsidyJuridicalAnswerVariantModel extends UpdateSubsidyJuridicalModel {

    constructor(
        readonly id: number,
        readonly answerVariant: List<{ question: List<{ id: number, answer: List<number> }> }>,
    ) {
        super(id);
    }
}