import image from "./img_development.png";
import React from "react";

export function _404Page() {

    return (
        <div className="flex flex-col items-center">
            <div>К сожалению, мы не можем найти запрошенную вами страницу.</div>
            <div>URL-адрес может быть написан с ошибкой или страница, которую вы ищете, больше недоступна.</div>
            <img src={image}/>
        </div>

    );
}