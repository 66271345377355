import {ReactNode, useEffect, useState} from "react";
import {cloneDeep, isEmpty, isEqual, result} from "lodash"
import {IconArrow} from "@core/ui/icon/IconArrow";
import {IconSuccess} from "@core/ui/icon/IconSuccess";
import {IconMinus} from "@core/ui/icon/IconMinus";
import {Logger} from "@core/logger/Logger";
import {IconClose} from "@core/ui/icon/IconClose";
import {List} from "@core/list/List";

interface FormSelectSearchMultipleProps<T> {
    placeholder?: string
    name?: string
    defValue?: List<string>
    options: Array<Option<T>>
}

export interface Option<T> {
    name: string,
    value: T,
    key: string,
    children?: Array<Option<T>>
    expand?: boolean
    isChecked: boolean,
}

export function FormSelectTreeMultiple<T>(props: FormSelectSearchMultipleProps<T>) {

    const [searchText, setSearchText] = useState<string>("")
    const [options, setOptions] =
        useState<Array<Option<T>>>(optionMapping(props.options))
    const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false)
    const [selectOptions, setSelectOptions] = useState<Array<Option<T>>>([])

    const [searchOptions, setSearchOptions] = useState<Array<Option<T>>>([])

    function optionMapping(options: Array<Option<T>>): Array<any> {
        return options.map<Option<T>>(it => {
            return {
                name: it.name,
                value: it.value,
                key: it.key,
                expand: it.expand ?? false,
                isChecked: it.isChecked ?? false,
                children: optionMapping(it.children ?? [])
            }
        })
    }

    function onClickArrow(option: Option<T>) {
        let newOptions = cloneDeep(options)
        newOptions = optionsMappingClickArrow(newOptions, option)
        setOptions([...newOptions])
    }

    function onClickOption(option: Option<T>, parentOption?: Option<T>) {
        option.isChecked = !option.isChecked

        onCheckedChildren(option, option.isChecked)

        if (parentOption) {
            onCheckedParent(parentOption, option.isChecked)
        }


        setSelectOptions([...findSelectOptions(options)])
        setOptions([...options])
    }

    function findSelectOptions(childrenOptions: Array<Option<T>>): Array<Option<T>> {
        let newSelectOptions: Array<Option<T>> = []
        childrenOptions.some(it => {
            if (it.isChecked) {
                newSelectOptions.push(it)
            } else {
                if (it.children) {
                    const select: Array<Option<T>> = findSelectOptions(it.children)
                    if (select.length != 0) {
                        newSelectOptions = newSelectOptions.concat(select)
                    }
                }
            }
        })
        return newSelectOptions
    }

    function onCheckedChildren(parentOption: Option<T>, checked: boolean) {
        parentOption.children?.forEach(it => {
            it.isChecked = checked
            onCheckedChildren(it, checked)
        })
    }

    function onCheckedParent(parentOption: Option<T>, checked: boolean) {
        let parentChecked = checked
        parentOption.children?.forEach(it => {
            parentChecked = parentChecked && it.isChecked
        })
        parentOption.isChecked = parentChecked
    }

    function optionsMappingClickArrow(options: Array<Option<T>>, searchOption: Option<T>, parentOption?: Option<T>): Array<Option<T>> {
        let parentCheck = true
        return options.map(it => {
            if (isEqual(it, searchOption)) {
                it.expand = !it.expand
                if (!it.expand) {
                    it.children = optionsMappingChildClickArrow(it.children ?? [], searchOption, it.expand, it)
                }
            } else {
                it.children = optionsMappingClickArrow(it.children ?? [], searchOption, it)
            }
            return it
        })
    }

    function optionsMappingChildClickArrow(options: Array<Option<T>>, searchOption: Option<T>, isParenCheck: boolean, parentOption?: Option<T>): Array<Option<T>> {
        return options.map(it => {
            it.expand = isParenCheck
            it.children = optionsMappingChildClickArrow(it.children ?? [], searchOption, isParenCheck, it)
            return it
        })
    }

    function onClickDropdown() {
        setVisibleDropdown(!visibleDropdown)
    }

    function searchOption(searchText: string) {

    }

    useEffect(() => {
        if (props.defValue == undefined) {
            return
        }
        createParentCheckedTree(props.defValue, options)

    }, [props.defValue]);

    function createParentCheckedTree(list: List<string>, option: Array<Option<T>>, parentOption?: Option<T>) {

        option.forEach((it) => {
            if (it.children !== undefined && it.children.length > 0) {
                createParentCheckedTree(list, it.children, it)
            } else {
                const key = list.find(def => def == it.key)
                if (key !== undefined) {
                    onClickOption(it, parentOption)
                }
            }
        })
    }

    useEffect(() => {
        searchOption(searchText)
    }, [searchText]);

    function renderTree(options: Array<Option<T>>, parentOption?: Option<T>) {
        return options.map(it => {
            return (
                <div key={it.key} className="flex flex-col w-full">
                    <div className="flex flex-row items-center gap-x-2">
                        <div className={`cursor-pointer ${it.expand ? "rotate-90" : ""}`}
                             onClick={_ => onClickArrow(it)}>
                            <IconArrow/>
                        </div>
                        <div className="cursor-pointer"
                             onClick={_ => onClickOption(it, parentOption)}>
                            {renderCheckBox(it)}
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="cursor-pointer p-1 w-full
                hover:bg-gray-200 hover:rounded-lg
                active:bg-gray-300 active:rounded-lg"
                                 onClick={_ => onClickOption(it, parentOption)}>
                                {"(" + it.key + ") " + it.name}
                            </div>
                        </div>
                    </div>
                    <div className="ml-8">
                        {it.expand ? renderTree(it.children ?? [], it) : null}
                    </div>
                </div>
            )
        })
    }

    function isSomeRenderCheckBox(option: Option<T>): boolean {
        let result = false
        option.children?.forEach(it => {
            if (!result) {
                if (it.isChecked!!) {
                    result = true
                } else {
                    result = isSomeRenderCheckBox(it)
                }
            }
        })
        return result
    }

    function renderCheckBox(option: Option<T>) {
        if (option.isChecked) {
            return (
                <div className="w-4 h-4 border rounded-sm ">
                    <IconSuccess/>
                </div>
            )
        } else if (isSomeRenderCheckBox(option)) {
            return (
                <div className="w-4 h-4 border rounded-sm bg-gray-500">
                    <IconMinus/>
                </div>
            )
        } else {
            return (
                <div className="w-4 h-4 border rounded-sm"/>
            )
        }
    }

    function onMouseLeave() {
        setVisibleDropdown(false)
    }

    function renderSelectOption(options: Array<Option<T>>): ReactNode {
        const render = options.map(it => {
            if (it.isChecked) {
                return (
                    <div key={it.key} className="flex flex-row items-center gap-x-2 bg-gray-100 rounded-lg w-fit p-1">
                        <div>{it.key}</div>
                        <div className="flex bg-red-400 h-5 w-5 items-center rounded-sm cursor-pointer
                        hover:bg-red-500
                        active:bg-red-600"
                             onClick={_ => onClickOption(it)}><IconClose/></div>
                    </div>
                )
            }
        })
        if (isEmpty(render)) {
            return props.placeholder
        } else {
            return render
        }
    }

    function getLastChildren(options: Array<Option<T>>): string {
        let result: Array<T> = []
        options.forEach(it => {
            if (it.children !== undefined && it.children.length > 0) {
                result.push(...selectLastChildren(it))
            } else {
                result.push(it.value)
            }
        })
        return JSON.stringify(Array.from(new Set(result)))
    }

    function selectLastChildren(option: Option<T>): Array<T> {
        let result: Array<T> = []
        option.children?.forEach(it => {
            if (it.children !== undefined && it.children.length > 0) {
                result.push(...selectLastChildren(it))
            } else {
                result.push(it.value)
            }
        })
        return result
    }

    return (
        <div
            className={`relative flex-col bg-white w-full select-none ${visibleDropdown ? "rounded-t-lg" : "rounded-lg "} border`}>

            <input name={props.name}
                   input-type="array"
                // value={JSON.stringify(selectOptions.map(it => it.value))}
                   value={getLastChildren(selectOptions)}
                   readOnly={true}
                   hidden/>

            <div className="flex flex-row p-1">
                <div className="flex flex-wrap w-full gap-1">
                    {renderSelectOption(selectOptions)}
                </div>
                <div className="border-1 border-l rounded-lg border-g"></div>
                <div className={`flex w-8 h-6 self-center justify-center cursor-pointer
                ${visibleDropdown ? "rotate-90" : ""}`}
                     onClick={_ => onClickDropdown()}><IconArrow/></div>
            </div>
            {visibleDropdown ?
                <div className="absolute bg-white border-t rounded-b-lg w-full overflow-auto shadow-lg"
                     onMouseLeave={_ => onMouseLeave()}>
                    {renderTree(options)}
                </div> : <></>}
        </div>
    )
}
