import {SubsidyModel} from "@admin/feature/subsidy/domain/model/SubsidyModel";

export type JuridicalItemProps = {
    item: SubsidyModel
    onClickItem: () => void
}

export function JuridicalItem(props: JuridicalItemProps) {

    return (
        <div className="flex flex-col rounded-lg shadow-md p-2 border cursor-pointer
        hover:bg-gray-200
        active:bg-gray-300"
        onClick={props.onClickItem}>
            <div>{props.item.title}</div>
            <div>#{props.item.id}</div>
        </div>
    )
}