import {SubsidyInfoNetworkModel} from "@admin/feature/subsidy/data/network/model/SubisdyInfoNetworkModel";
import {List} from "@core/list/List";
import {AnswerVariantNetworkModel} from "@admin/feature/subsidy/data/network/model/AnswerVariantNetworkModel";
import {OkvedNetworkModel} from "@admin/feature/subsidy/data/network/model/OkvedNetworkModel";

export class SubsidyJuridicalDetailNetworkModel {

    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly isActive: boolean,
        public readonly isFavorite: boolean,
        public readonly info: List<SubsidyInfoNetworkModel>,
        public readonly okved: List<OkvedNetworkModel>,
        public readonly answerVariant: List<AnswerVariantNetworkModel>,
    ) {
    }
}