import Bac from "@core/ui/res/img_background.png";
import React from "react";

type props = {
    children: React.ReactNode
}
export function MainBackground(children: props) {

    return (
        <div
            className="absolute inset-0 flex"
            style={{backgroundImage: `url(${Bac})`}}
        >
            {children.children}
        </div>
    );
}