import React from "react";
import {Outlet} from "react-router-dom";
import {Form} from "@core/form/Form";
import {FormError} from "@core/form/form-settings";

type UserFormData = {
    id: number
    phone: Array<string>
    firstName: string
    name: string
    lastName: string
}

export function AdminMainScreen() {

    return (
        <div className="flex flex-col">
            <Outlet/>
        </div>
    );
}