import {SubsidyInfoNetworkModel} from "@admin/feature/subsidy/data/network/model/SubisdyInfoNetworkModel";
import {List} from "@core/list/List";

export class SubsidyNetworkModel {

    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly isActive: boolean,
        public readonly isFavorite: boolean,
        public readonly info: List<SubsidyInfoNetworkModel>,
    ) {
    }
}