import {API_KEY, API_URL} from "../../config";
import {NetworkResponse} from "./response/NetworkResponse";
import {Logger} from "../../core/logger/Logger";
import {NetworkError} from "./response/NetworkError";
import {UserStorageImpl} from "../../core/storage/user/UserStorageImpl";
import {UserStorage} from "../../core/storage/user/UserStorage";

export abstract class BaseClient {
    protected headers: Headers = new Headers()

    protected constructor(headers: Headers) {
        this.headers = headers
    }

    protected get<T>(url: string) {
        let options = {
            method: 'GET',
            headers: this.headers
        };

        return fetch(API_URL + url, options)
            .then(response => response.json())
            .then(response => new NetworkResponse<T>(response.data, response.error))
            .then(response => response.throwIfError())
            .catch(error => {
                this.handlerError(error)
                throw error
            })
    }

    protected post<T>(url: string, body: {} = {}) {
        let options = {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(body)
        };

        return fetch(API_URL + url, options)
            .then(response => response.json())
            .then(response => new NetworkResponse<T>(response.data, response.error))
            .then(response => response.throwIfError())
            .catch(error => {
                this.handlerError(error)
                throw error
            })
    }
    protected delete<T>(url: string, body: {} = {}) {
        let options = {
            method: 'DELETE',
            headers: this.headers,
            body: JSON.stringify(body)
        };

        return fetch(API_URL + url, options)
            .then(response => response.json())
            .then(response => new NetworkResponse<T>(response.data, response.error))
            .then(response => response.throwIfError())
            .catch(error => {
                this.handlerError(error)
                throw error
            })
    }
    protected put<T>(url: string, body: {} = {}) {
        let options = {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(body)
        };

        return fetch(API_URL + url, options)
            .then(response => response.json())
            .then(response => new NetworkResponse<T>(response.data, response.error))
            .then(response => response.throwIfError())
            .catch(error => {
                this.handlerError(error)
                throw error
            })
    }

    private handlerError(error: NetworkError) {
        switch (error.code) {
            case 403:
                window.location.replace("")
                break
        }
    }

}