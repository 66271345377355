import {InfoNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoNetworkModel";
import {InfoTypeNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoTypeNetworkModel";

export class SubsidyInfoNetworkModel extends InfoNetworkModel{
    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly value: string,
        public readonly type: InfoTypeNetworkModel
    ) {

        super(id, title, type);
    }
}