import {SubsidyNetworkModel} from "@admin/feature/subsidy/data/network/model/SubsidyNetworkModel";
import {List} from "@core/list/List";
import {SubsidyModel} from "@admin/feature/subsidy/domain/model/SubsidyModel";
import {SubsidyInfoNetworkModel} from "@admin/feature/subsidy/data/network/model/SubisdyInfoNetworkModel";
import {InfoTypeNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoTypeNetworkModel";
import {InfoTypeModel} from "@admin/feature/subsidy/domain/model/InfoTypeModel";
import {SubsidyInfoModel} from "@admin/feature/subsidy/domain/model/SubsidyInfoModel";
import {InfoNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoNetworkModel";
import {InfoModel} from "@admin/feature/subsidy/domain/model/InfoModel";
import {OkvedDetailNetworkModel} from "@admin/feature/subsidy/data/network/model/OkvedDetailNetworkModel";
import {OkvedDetailModel} from "@admin/feature/subsidy/domain/model/OkvedDetailModel";
import {QuestionNetworkModel} from "@admin/feature/subsidy/data/network/model/QuestionNetworkModel";
import {QuestionModel} from "@admin/feature/subsidy/domain/model/QuestionModel";
import {AnswerNetworkModel} from "@admin/feature/subsidy/data/network/model/AnswerNetworkModel";
import {AnswerModel} from "@admin/feature/subsidy/domain/model/AnswerModel";
import {
    SubsidyJuridicalDetailNetworkModel
} from "@admin/feature/subsidy/data/network/model/SubsidyJuridicalDetailNetworkModel";
import {SubsidyJuridicalDetailModel} from "@admin/feature/subsidy/domain/model/SubsidyJuridicalDetailModel";
import {OkvedNetworkModel} from "@admin/feature/subsidy/data/network/model/OkvedNetworkModel";
import {OkvedModel} from "@admin/feature/subsidy/domain/model/OkvedModel";
import {AnswerVariantModel} from "@admin/feature/subsidy/domain/model/AnswerVariantModel";
import {AnswerVariantNetworkModel} from "@admin/feature/subsidy/data/network/model/AnswerVariantNetworkModel";

export function toSubsidyJuridicalDetailModel(model:SubsidyJuridicalDetailNetworkModel):SubsidyJuridicalDetailModel{
    return new SubsidyJuridicalDetailModel(
        model.id,
        model.name,
        model.isActive,
        model.isFavorite,
        toListSubsidyInfoModel(model.info),
        toListOkvedModel(model.okved),
        toListAnswerVariantModel(model.answerVariant)
    )
}

export function toAnswerVariantModel(model:AnswerVariantNetworkModel):AnswerVariantModel{
    return new AnswerVariantModel(
        model.id,
        toListQuestionModel(model.question)
    )
}

export function toListAnswerVariantModel(it:List<AnswerVariantNetworkModel>):List<AnswerVariantModel>{
    return it.map(it=>toAnswerVariantModel(it))
}


export function toInfoModel(model: InfoNetworkModel): InfoModel {
    return new InfoModel(
        model.id,
        model.title,
        toInfoTypeModel(model.type)
    )
}

export function toListInfoModel(list: List<InfoNetworkModel>): List<InfoModel> {
    return list.map(it => toInfoModel(it))
}

export function toSubsidyModel(model: SubsidyNetworkModel): SubsidyModel {
    return new SubsidyModel(
        model.id,
        model.title,
        model.isActive,
        model.isFavorite,
        toListSubsidyInfoModel(model.info)
    )
}

export function toListSubsidyModel(list: List<SubsidyNetworkModel>): List<SubsidyModel> {
    return list.map(it => toSubsidyModel(it))
}

export function toListSubsidyInfoModel(list: List<SubsidyInfoNetworkModel>): List<SubsidyInfoModel> {
    return list.map(it => toSubsidyInfoModel(it))
}

export function toSubsidyInfoModel(model: SubsidyInfoNetworkModel): SubsidyInfoModel {
    return new SubsidyInfoNetworkModel(
        model.id,
        model.title,
        model.value,
        toInfoTypeModel(model.type),
    )
}

export function toInfoTypeModel(model: InfoTypeNetworkModel): InfoTypeModel {
    return new InfoTypeNetworkModel(
        model.id,
        model.title,
        model.description,
    )
}

export function toListInfoTypeModel(list: List<InfoTypeNetworkModel>): List<InfoTypeModel> {
    return list.map(it => toInfoTypeModel(it))
}

export function toOkvedDetailModel(model: OkvedDetailNetworkModel): OkvedDetailModel {
    return new OkvedDetailModel(
        model.id,
        model.name,
        toListOkvedDetailModel(model.children)
    )
}

export function toListOkvedDetailModel(list: List<OkvedDetailNetworkModel>): List<OkvedDetailModel> {
    return list.map(it => toOkvedDetailModel(it))
}

export function toOkvedModel(model: OkvedNetworkModel): OkvedModel {
    return new OkvedModel(
        model.id,
        model.name,
    )
}

export function toListOkvedModel(list: List<OkvedNetworkModel>): List<OkvedModel> {
    return list.map(it => toOkvedModel(it))
}

export function toQuestionModel(model: QuestionNetworkModel): QuestionModel {
    return new QuestionModel(
        model.id,
        model.title,
        model.isMultiple,
        toListAnswerModel(model.answers)
    )
}

export function toListQuestionModel(list: List<QuestionNetworkModel>): List<QuestionModel> {
    return list.map(it => toQuestionModel(it))
}

export function toAnswerModel(model: AnswerNetworkModel): AnswerModel {
    return new AnswerModel(
        model.id,
        model.title,
    )
}

export function toListAnswerModel(list: List<AnswerNetworkModel>): List<AnswerModel> {
    return list.map(it => toAnswerModel(it))
}