import {useEffect, useState} from "react";
import {InfoModel} from "@admin/feature/subsidy/domain/model/InfoModel";
import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import {cloneDeep} from "lodash";
import {useAppContext} from "@app/context/AppContextProvider";
import {ProgressBar} from "@core/ui/progress/ProgressBar";
import {FormFieldSet} from "@core/form/fieldset/FormFieldSet";
import {ButtonRemove} from "@core/ui/button/ButtonRemove";
import {ButtonPrimary} from "@core/ui/button/ButtonPrimary";
import {FormInputReachText} from "@core/form/input-reach-text/FormInputReachText";
import {List} from "@core/list/List";
import {Form} from "@core/form/Form";
import {FormSubmit} from "@core/form/submit/FormSubmit";
import {UpdateSubsidyJuridicalInfoModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalInfoModel";
import {ChangeSubsidyInfoFormModel} from "@admin/feature/subsidy/ui/form/model/ChangeSubsidyInfoFormModel";

type InfoSelector = {
    selectAr: List<{ info: InfoModel, isDisable: boolean }>,
    select?: InfoModel,
    value?: string
}

type FormChangeSubsidyInfoProps = {
    id: number
    defValue?: List<{ info: InfoModel, value: string }>
}

export function FormChangeSubsidyInfo(props: FormChangeSubsidyInfoProps) {

    const interactor = SubsidyInteractorImpl.init()
    const context = useAppContext()

    const [totalInfo, setTotalInfo] = useState<List<InfoModel>>(new List<InfoModel>())
    const [subsidyInfo, setSubsidyInfo] = useState<List<InfoSelector>>(new List<InfoSelector>())
    const [isAddInfo, setIsAddInfo] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        interactor.getJuridicalInfoList()
            .then(it => setTotalInfo(it))
            .catch(context.snackbar.showError)
            .finally(() => setLoading(false))
    }, []);

    useEffect(() => {
        const a = cloneDeep(totalInfo)

        const selectAr = totalInfo?.map<{ info: InfoModel, isDisable: boolean }>(info => {
            let isDisable = false
            if (props.defValue!.find(it => it.info.id === info.id) !== undefined) {
                isDisable = true
            }
            return {info: info, isDisable: isDisable}
        })

        const result = props.defValue!
            .map(it => {
                return {selectAr: selectAr!, select: it.info, value: it.value}
            })

        setSubsidyInfo(new List<InfoSelector>(...result))
        // props.defValue?.forEach((it, index) => {
        //     onClickAddInfo()
        //     onChangeSelect(it.info, index)
        // })
    }, [totalInfo]);

    function onUpdate(data: ChangeSubsidyInfoFormModel) {

        const update = new UpdateSubsidyJuridicalInfoModel(props.id, data.info)
        interactor.updateSubsidyJuridical(update)
            .then((it)=>context.snackbar.show("Информация обновлена"))
            .catch((error)=>context.snackbar.showError(error))
            .finally()
    }

    function onClickRemoveInfo(index: number) {
        let newSubsidyInfo = subsidyInfo!.copy()
        newSubsidyInfo.splice(index, 1)
        setSubsidyInfo(newSubsidyInfo)
    }

    function onChangeValue(text: string, index: number) {
        let newSubsidyInfo = subsidyInfo.copy()
        newSubsidyInfo[index].value = text
        setSubsidyInfo(newSubsidyInfo)
    }

    function onChangeSelect(select: InfoModel, index: number) {
        let newSubsidyInfo = cloneDeep(subsidyInfo)
        newSubsidyInfo[index].select = select

        const selectAr = totalInfo.map<{ info: InfoModel, isDisable: boolean }>(info => {
            let isDisable = false
            if (newSubsidyInfo.find(it => it.select?.id === info.id) !== undefined) {
                isDisable = true
            }
            return {info: info, isDisable: isDisable}
        })

        newSubsidyInfo = newSubsidyInfo.map(it => {
            return {
                selectAr: selectAr,
                select: it.select,
                value: it.value
            }
        })

        setSubsidyInfo(newSubsidyInfo)
    }


    const onClickAddInfo = () => {

        const newSelectorAr = totalInfo.map<{
            info: InfoModel,
            isDisable: boolean
        }>((it, index, array) => {
            return {
                info: it,
                isDisable: false
            }
        })

        subsidyInfo.map(value => {
            totalInfo.map((value1, index) => {
                if (value.select?.id === value1.id) {
                    newSelectorAr[index].isDisable = true
                }
            })
        })

        if (subsidyInfo.length === totalInfo.length - 1) {
            setIsAddInfo(false)
        } else {
            setIsAddInfo(true)
        }

        const a = subsidyInfo.copy()

        a.push({
            selectAr: newSelectorAr,
            select: undefined,
            value: undefined
        })

        setSubsidyInfo(a)
    }

    useEffect(() => {
    }, [subsidyInfo]);

    return (
        <Form<ChangeSubsidyInfoFormModel> className="flex flex-col w-full gap-y-2 border rounded-lg shadow-lg p-2"
                                             onSuccessSerialize={onUpdate}>
            <div className="w-full text-center border-b">Информация по субсидии</div>
            <div className="flex flex-col bg-white rounded-lg">
                {loading ? <div className="self-center"><ProgressBar/></div> :
                    <div>
                        <FormFieldSet className="flex flex-col gap-y-4"
                                      name="info[]">
                            {
                                subsidyInfo.length ? subsidyInfo.map((value, index) =>
                                    (
                                        <FormFieldSet className="flex flex-row gap-x-4 w-full" key={index}>
                                            <div className="w-full">
                                                <select
                                                    className="w-full"
                                                    name="id"
                                                    input-type="number"
                                                    id={String(index)}
                                                    value={value.select?.id.toString()}
                                                    onChange={event =>
                                                        onChangeSelect(totalInfo.find(it => event.currentTarget.value === it.id.toString())!!, index)
                                                    }
                                                    defaultValue={-1}
                                                >
                                                    <option className="text-transparent" value={-1} key={-1}>None</option>
                                                    {
                                                        value.selectAr.map((info) => (
                                                            <option
                                                                value={info.info.id}
                                                                key={info.info.id}
                                                                disabled={info.isDisable}>{info.info.title} ({info.info.type.title})</option>
                                                        ))
                                                    }
                                                </select>
                                                <FormInputReachText name="value" value={value.value}/>
                                            </div>
                                            <ButtonRemove className="flex w-fit h-fit self-start justify-center"
                                                          onClick={() => onClickRemoveInfo(index)}/>
                                        </FormFieldSet>
                                    )
                                ) : <></>
                            }

                        </FormFieldSet>
                        {
                            isAddInfo ?
                                <ButtonPrimary className="flex p-2 w-full justify-center" label="Добавить"
                                               onClick={onClickAddInfo}/>
                                : <></>
                        }
                    </div>
                }
            </div>

            <FormSubmit label={"Обновить"}/>
        </Form>
    )
}