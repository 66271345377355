import {SubsidyRepository} from "@admin/feature/subsidy/data/repository/SubsidyRepository";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {List} from "@core/list/List";
import {SubsidyNetworkClientImpl} from "@admin/feature/subsidy/data/network/SubsidyNetworkClientImpl";
import {SubsidyNetworkClient} from "@admin/feature/subsidy/data/network/SubsidyNetworkClient";
import {SubsidyNetworkModel} from "@admin/feature/subsidy/data/network/model/SubsidyNetworkModel";
import {InfoNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoNetworkModel";
import {InfoTypeNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoTypeNetworkModel";
import {OkvedDetailNetworkModel} from "@admin/feature/subsidy/data/network/model/OkvedDetailNetworkModel";
import {QuestionNetworkModel} from "@admin/feature/subsidy/data/network/model/QuestionNetworkModel";
import {AddSubsidyPhysical} from "@admin/feature/subsidy/domain/model/SubsidyPhysicalFormSchema";
import {AddSubsidyJuridical} from "@admin/feature/subsidy/domain/model/SubsidyJuridicalFormSchema";
import {
    SubsidyJuridicalDetailNetworkModel
} from "@admin/feature/subsidy/data/network/model/SubsidyJuridicalDetailNetworkModel";
import {UpdateSubsidyJuridicalModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalModel";

export class SubsidyRepositoryImpl implements SubsidyRepository {

    constructor(
        private readonly networkClient: SubsidyNetworkClient
    ) {
    }

    static init = (): SubsidyRepository =>
        new SubsidyRepositoryImpl(
            SubsidyNetworkClientImpl.init()
        )

    getSubsidyJuridicalList = (): ExtPromise<List<SubsidyNetworkModel>> =>
        this.networkClient.getSubsidyJuridicalList()

    getSubsidyJuridical = (id: number): ExtPromise<SubsidyNetworkModel> =>
        this.networkClient.getSubsidyJuridical(id)

    getSubsidyJuridicalDetail = (id: number): ExtPromise<SubsidyJuridicalDetailNetworkModel | null> =>
        this.networkClient.getSubsidyJuridicalDetail(id)

    getPhysicalInfoList = (): ExtPromise<List<InfoNetworkModel>> =>
        this.networkClient.getPhysicalList()

    getJuridicalInfoList = (): ExtPromise<List<InfoNetworkModel>> =>
        this.networkClient.getJuridicalList()

    addSubsidyJuridical = (subsidy: AddSubsidyJuridical): ExtPromise<SubsidyNetworkModel> =>
        this.networkClient.addSubsidyJuridical(subsidy)

    addSubsidyPhysical = (subsidy: AddSubsidyPhysical): ExtPromise<SubsidyNetworkModel> =>
        this.networkClient.addSubsidyPhysical(subsidy)

    getInfoTypeList = (): ExtPromise<List<InfoTypeNetworkModel>> =>
        this.networkClient.getInfoTypeList()

    getOkvedList = (): ExtPromise<List<OkvedDetailNetworkModel>> =>
        this.networkClient.getOkvedList()

    getQuestionJuridicalList = (): ExtPromise<List<QuestionNetworkModel>> =>
        this.networkClient.getQuestionJuridicalList()

    getQuestionPhysicalList = (): ExtPromise<List<QuestionNetworkModel>> =>
        this.networkClient.getQuestionPhysicalList()

    updateSubsidyJuridical = (data: UpdateSubsidyJuridicalModel): ExtPromise<boolean> =>
        this.networkClient.updateSubsidyJuridical(data)
}