import React from "react";
import {AdminSubsidyDealListPage} from "./list/AdminSubsidyDealListPage";

export function FeatureDeal() {

    return (
        <div>
            Список Дел
            <AdminSubsidyDealListPage/>
        </div>
    );
}