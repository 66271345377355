import {SnackbarType} from "@core/ui/snackbar/SnackbarType";
import {useAppContext} from "../../../app/context/AppContextProvider";
import {SyntheticEvent, useEffect, useState} from "react";
import {IconSuccess} from "@core/ui/icon/IconSuccess";
import {IconError} from "@core/ui/icon/IconError";
import {IconInfo} from "@core/ui/icon/IconInfo";

export interface SnackbarComponent {

    show: (message: string, type?: SnackbarType) => void

    showError: (error?: Error) => void
}

export const initialSnackbarComponent: SnackbarComponent = {

    show: (_?: string, __?: SnackbarType) => {
    },

    showError: (_?: Error) => {
    }
}

export function Snackbar() {

    const context = useAppContext()

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [type, setType] = useState<SnackbarType | undefined>(SnackbarType.INFO)
    const [message, setMessage] = useState<string>("")

    const show = () => {
        setIsOpen(true)
    }

    const close = () => {
        setIsOpen(false)
    }

    function onClick(event: SyntheticEvent<HTMLDivElement>) {
        close()
    }

    context.snackbar.show = (message: string, type?: SnackbarType) => {
        setMessage(message)
        setType(type)
        show()
    }

    context.snackbar.showError = (error?: Error) => {
        const message = error ? error.message : "Что-то пошло не так"
        setMessage(message)
        setType(SnackbarType.ERROR)
        show()
    }

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (isOpen) {
            timer = setTimeout(() => {
                setIsOpen(false);
            }, 5000);
        }

        return () => clearTimeout(timer);
    }, [isOpen]);

    return (
        <div className={`z-50 flex items-center fixed right-10 bottom-10 px-5 py-4 border-r-8 rounded-lg drop-shadow-lg cursor-pointer
             ${type == SnackbarType.SUCCESS ? ' bg-white border-green-500' : type == SnackbarType.ERROR ? ' bg-white border-red-500' : ' bg-white border-blue-500'}
             ${isOpen ? 'opacity-100 ' : 'pointer-events-none opacity-0 translate-x-full'} transition duration-700 ease-in-out`}
             onClick={onClick}>

            {/*   Type   */}
            <div
                className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 rounded-lg dark:bg-green-800 dark:text-green-200">
                {type == SnackbarType.SUCCESS ? <IconSuccess/> : type == SnackbarType.ERROR ? <IconError/> :
                    <IconInfo/>}
                <span className="sr-only">Check icon</span>
            </div>

            {/*   Message   */}
            <div className="ml-3 text-sm font-normal text-black">{message}</div>

            {/*   Button   */}
            <button type="button"
                    className="ml-4 -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                    data-dismiss-target="#toast-success" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"></path>
                </svg>
            </button>
        </div>
    )

}