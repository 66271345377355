import {List} from "@core/list/List";
import {QuestionNetworkModel} from "@admin/feature/subsidy/data/network/model/QuestionNetworkModel";

export class AnswerVariantNetworkModel {

    constructor(
        readonly id: number,
        readonly question: List<QuestionNetworkModel>,
    ) {
    }
}