import {FormInput} from "@core/form/input/FormInput";
import {CheckBox} from "@core/ui/checkbox/CheckBox";
import {Form} from "@core/form/Form";
import {FormSubmit} from "@core/form/submit/FormSubmit";
import {Logger} from "@core/logger/Logger";
import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import {UpdateSubsidyJuridicalCommonModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicaCommonlModel";
import {useState} from "react";
import {useAppContext} from "@app/context/AppContextProvider";
import {SubsidyCommonFormSchema} from "@admin/feature/subsidy/ui/form/model/SubsidyCommonFormSchema";

interface FormChangeSubsidyCommonProps {
    id:number
    defValue: { name: string, isActive: boolean, isFavorite: boolean }
}

export function FormChangeSubsidyCommon(props: FormChangeSubsidyCommonProps) {

    const interactor = SubsidyInteractorImpl.init()
    const context = useAppContext()

    const [updating, setUpdating] = useState(false)
    const [btnUpdateVisible,setBtnUpdateVisible] = useState(false)

    function onSuccessSerialize(data: SubsidyCommonFormSchema) {

        setUpdating(true)
        setBtnUpdateVisible(false)

        const update =
            new UpdateSubsidyJuridicalCommonModel(
                props.id, data.name, data.is_active, data.is_favorite
            )
        interactor.updateSubsidyJuridical(update)
            .then( it=>context.snackbar.show("Обновлено"))
            .catch(it=>{
                context.snackbar.showError(it)
                setBtnUpdateVisible(true)
            })
            .finally(() => setUpdating(false))
    }

    return (
        <Form<SubsidyCommonFormSchema> className="flex flex-col w-full gap-y-2 border rounded-lg shadow-lg p-2"
              onSuccessSerialize={onSuccessSerialize}>
            <div className="flex flex-col">
                <div>Название субсидии</div>
                <FormInput name="name" defaultValue={props.defValue.name}/>
            </div>
            <div className="flex flex-row">
                <CheckBox name="is_active" defaultChecked={props.defValue.isActive}/>
                <div>Видимость</div>
            </div>
            <div className="flex flex-row">
                <CheckBox name="is_favorite" defaultChecked={props.defValue.isFavorite}/>
                <div>Избранная</div>
            </div>
            <FormSubmit label={"Обновить"}/>
        </Form>
    )
}