import {ButtonNavigationModel} from "./model/ButtonNavigationModel";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Logger} from "@core/logger/Logger";

export function ButtonNavigation(parameter: ButtonNavigationModel) {

    const [state, setState] = useState(false)
    const location = useLocation();
    function onClick() {
        setState(true)
    }

    useEffect(() => {
        setState(window.location.href.includes(parameter.to))
    }, [location])

    return (
        <Link className={`rounded shadow p-1 m-1 flex hover:bg-primary-100 focus:bg-primary-300 ${state?"bg-primary-300 hover:none":"bg-white"}`}
              to={`${parameter.to}`}>
            <div>{parameter.icon}</div>
            {parameter.text}
        </Link>
    );
}