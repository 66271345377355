import {SubsidyNetworkClient} from "@admin/feature/subsidy/data/network/SubsidyNetworkClient";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {List} from "@core/list/List";
import {NetworkProvider} from "@admin/core/network/NetworkProvider";
import {NetworkProviderImpl} from "@admin/core/network/NetworkProviderImpl";
import {SubsidyNetworkModel} from "@admin/feature/subsidy/data/network/model/SubsidyNetworkModel";
import {
    toInfoTypeNetworkModel,
    toListInfoNetworkModel,
    toListInfoTypeNetworkModel,
    toListOkvedDetailNetworkModel,
    toListQuestionNetworkModel,
    toListSubsidyNetworkModel, toSubsidyJuridicalDetailNetworkModel, toSubsidyJuridicalRequest,
    toSubsidyNetworkModel, toSubsidyPhysicalRequest, toUpdateJuridicalRequest
} from "@admin/feature/subsidy/data/DataMapper";
import {InfoNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoNetworkModel";
import {QuestionNetworkModel} from "@admin/feature/subsidy/data/network/model/QuestionNetworkModel";
import {OkvedDetailNetworkModel} from "@admin/feature/subsidy/data/network/model/OkvedDetailNetworkModel";
import {InfoTypeNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoTypeNetworkModel";
import {AddSubsidyJuridical} from "@admin/feature/subsidy/domain/model/SubsidyJuridicalFormSchema";
import {AddSubsidyPhysical} from "@admin/feature/subsidy/domain/model/SubsidyPhysicalFormSchema";
import {
    SubsidyJuridicalDetailNetworkModel
} from "@admin/feature/subsidy/data/network/model/SubsidyJuridicalDetailNetworkModel";
import {UpdateSubsidyJuridicalModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalModel";

export class SubsidyNetworkClientImpl implements SubsidyNetworkClient {

    constructor(
        private readonly networkProvider: NetworkProvider
    ) {
    }

    static init = (): SubsidyNetworkClient =>
        new SubsidyNetworkClientImpl(
            NetworkProviderImpl.init()
        )

    getSubsidyJuridicalList = (): ExtPromise<List<SubsidyNetworkModel>> =>
        this.networkProvider.getSubsidyClient()
            .getSubsidyJuridicalList()
            .then(it => toListSubsidyNetworkModel(it))

    getSubsidyJuridical = (id: number): ExtPromise<SubsidyNetworkModel> =>
        this.networkProvider.getSubsidyClient()
            .getSubsidyJuridical(id)
            .then(it => toSubsidyNetworkModel(it))

    getSubsidyJuridicalDetail = (id: number): ExtPromise<SubsidyJuridicalDetailNetworkModel | null> =>
        this.networkProvider.getSubsidyClient()
            .getSubsidyJuridicalDetail(id)
            .then(it => it ? toSubsidyJuridicalDetailNetworkModel(it) : it)


    getPhysicalList = (): ExtPromise<List<InfoNetworkModel>> =>
        this.networkProvider.getSubsidyClient()
            .getPhysicalInfoList()
            .then(it => toListInfoNetworkModel(it))

    getJuridicalList = (): ExtPromise<List<InfoNetworkModel>> =>
        this.networkProvider.getSubsidyClient()
            .getJuridicalInfoList()
            .then(it => toListInfoNetworkModel(it))

    getInfoTypeList = (): ExtPromise<List<InfoTypeNetworkModel>> =>
        this.networkProvider.getSubsidyClient()
            .getInfoTypeList()
            .then(it => toListInfoTypeNetworkModel(it))

    getSubsidyPhysicalList = (): ExtPromise<List<SubsidyNetworkModel>> =>
        this.networkProvider.getSubsidyClient()
            .getSubsidyPhysicalList()
            .then(it => toListSubsidyNetworkModel(it))

    addSubsidyJuridical = (subsidy: AddSubsidyJuridical): ExtPromise<SubsidyNetworkModel> =>
        this.networkProvider.getSubsidyClient()
            .addSubsidyJuridical(toSubsidyJuridicalRequest(subsidy))
            .then(it => toSubsidyNetworkModel(it))

    addSubsidyPhysical = (subsidy: AddSubsidyPhysical): ExtPromise<SubsidyNetworkModel> =>
        this.networkProvider.getSubsidyClient()
            .addSubsidyPhysical(toSubsidyPhysicalRequest(subsidy))
            .then(it => toSubsidyNetworkModel(it))

    getOkvedList = (): ExtPromise<List<OkvedDetailNetworkModel>> =>
        this.networkProvider.getOkvedClient()
            .getOkvedList()
            .then(it => toListOkvedDetailNetworkModel(it))

    getQuestionJuridicalList = (): ExtPromise<List<QuestionNetworkModel>> =>
        this.networkProvider.getSubsidyClient()
            .getQuestionJuridicalList()
            .then(it => toListQuestionNetworkModel(it))

    getQuestionPhysicalList = (): ExtPromise<List<QuestionNetworkModel>> =>
        this.networkProvider.getSubsidyClient()
            .getQuestionPhysicalList()
            .then(it => toListQuestionNetworkModel(it))

    updateSubsidyJuridical = (data: UpdateSubsidyJuridicalModel): ExtPromise<boolean> =>
        this.networkProvider.getSubsidyClient()
            .updateSubsidyJuridical(toUpdateJuridicalRequest(data))

}