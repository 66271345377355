import {InfoTypeModel} from "@admin/feature/subsidy/domain/model/InfoTypeModel";

export class InfoModel{

    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly type: InfoTypeModel
    ) {
    }
}