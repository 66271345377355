import React, {ReactNode, useEffect, useState} from 'react';
import {AppAdmin} from "@admin/AppAdmin";
import {UserStorage} from "@core/storage/user/UserStorage";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";
import {AppContextProvider} from "./context/AppContextProvider";
import {AppAnon} from "@anon/AppAnon";
import {AppManager} from "@manager/AppManager";
import {AppClient} from "@client/AppClient";
import {Route, Routes} from "react-router-dom";
import {FeaturePrivacyPolicy} from "./feature/privacy-policy/FeaturePrivacyPolicy";
import {ProfileRole} from "@core/storage/user/model/ProfileRole";
import {LoadingPage} from "@core/ui/pages/LoadingPage";
import {FeatureSupport} from "@app/feature/support/FeatureSupport";

export function App() {

    const userStorage: UserStorage = new UserStorageImpl()

    const [role, setRole] = useState<ProfileRole>()

    useEffect(() => {
        const role = userStorage.getProfile()?.role ?? ProfileRole.ANON
        setRole(role);
    }, [])

    function getApplication(): ReactNode {

        return role == ProfileRole.ADMIN ? <AppAdmin/> :
            role == ProfileRole.CONTENT_MANAGER ? <AppManager/> :
                role == ProfileRole.CLIENT ? <AppClient/> :
                    role == ProfileRole.LAWYER ? <AppAnon/> :
                        role == ProfileRole.BOT ? <AppAnon/> :
                            role == ProfileRole.ANON ? <AppAnon/> :
                                <LoadingPage/>
    }

    return (
        <AppContextProvider>
            <Routes>
                <Route path="/privacy-policy" element={<FeaturePrivacyPolicy/>}/>
                <Route path="/support" element={<FeatureSupport/>}/>
                <Route path="/*" element={getApplication()}/>
            </Routes>
        </AppContextProvider>
    )
}

export enum RoleModel {
    ADMIN = "Admin",
    USER = "User",
    CONTENT_MANAGER = "Content_manager",
    ANON = "",
}

export namespace RoleModel {
    export function toString(value: RoleModel): string {
        return value.toString()
    }

    export function parse(value: string): RoleModel {
        switch (value) {
            case "Admin":
                return RoleModel.ADMIN
            case "User":
                return RoleModel.USER
            case "Content_manager":
                return RoleModel.CONTENT_MANAGER
            default:
                return RoleModel.ANON
        }
    }
}