import {List} from "@core/list/List";
import {AnswerRequest} from "@admin/core/network/client/subsidy/model/request/AnswerRequest";

export class QuestionRequest {

    constructor(
        public readonly id: number,
        public readonly answer: List<number>,
    ) {
    }
}