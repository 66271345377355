import {useEffect, useState} from "react";
import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import {useAppContext} from "@app/context/AppContextProvider";
import {List} from "@core/list/List";
import {OkvedDetailModel} from "@admin/feature/subsidy/domain/model/OkvedDetailModel";
import {ProgressBar} from "@core/ui/progress/ProgressBar";
import {FormSelectTreeMultiple, Option} from "@core/form/select-tree-multiple/FormSelectTreeMultiple";
import {FormFieldSet} from "@core/form/fieldset/FormFieldSet";
import {Logger} from "@core/logger/Logger";
import {OkvedModel} from "@admin/feature/subsidy/domain/model/OkvedModel";

interface FormSelectOkvedProps {
    defValue?: List<OkvedModel>
}

export function FormSelectOkved(props: FormSelectOkvedProps) {

    const interactor = SubsidyInteractorImpl.init()
    const context = useAppContext()

    const [loading, setLoading] = useState(true)
    const [okvedList, setOkvedList] = useState<Array<Option<OkvedDetailModel>>>([])

    function updateOkved() {
        interactor.getOkvedList()
            .then(it => setOkvedList(toArrayOption(it)))
            .catch(context.snackbar.showError)
            .finally(() => setLoading(false))
    }

    function toOption(model: OkvedDetailModel): Option<OkvedDetailModel> {
        return {value: model, name: model.name, key: model.id, children: toArrayOption(model.children), isChecked: false}
    }

    function toArrayOption(list: List<OkvedDetailModel>): Array<Option<OkvedDetailModel>> {
        return list.map(it => toOption(it))
    }

    useEffect(() => {
        updateOkved()
    }, []);

    useEffect(() => {
    }, [okvedList]);

    return (
        <div className="flex flex-col bg-white rounded-lg">
            <div className="w-full text-center">ОКВЭД</div>
            <div className="flex flex-col bg-white rounded-lg">
                {
                    loading ? <ProgressBar/>
                        :
                        <FormSelectTreeMultiple
                            options={okvedList}
                            placeholder="Нажмите чтобы выбрать"
                            name="okved"
                        />
                }
            </div>
        </div>
    )
}