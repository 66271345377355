   import React from "react";
import {_404Page} from "@core/ui/pages/404/_404Page";
   import {UserListPage} from "./list/UserListPage";

export function FeatureUser() {

    return (
        <div>
            Список пользователей
            <UserListPage/>
        </div>
    )
}