import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";

export function TabNavigation(parameter: { to: string, text: string, }) {

    const [state, setState] = useState(false)
    const location = useLocation();

    useEffect(() => {
        setState(window.location.href.includes(parameter.to))
    }, [location])

    return (
        <Link
            className={`rounded shadow p-1 m-1 flex hover:bg-primary-100 focus:bg-primary-300 ${state ? "bg-primary-300 hover:none" : "bg-white"}`}
            to={`${parameter.to}`}>
            {parameter.text}
        </Link>
    );
}