import {SubsidyNetworkModel} from "@admin/feature/subsidy/data/network/model/SubsidyNetworkModel";
import {SubsidyResponse} from "@admin/core/network/client/subsidy/model/response/SubsidyResponse";
import {List} from "@core/list/List";
import {SubsidyInfoResponse} from "@admin/core/network/client/subsidy/model/response/SubsidyInfoResponse";
import {SubsidyInfoNetworkModel} from "@admin/feature/subsidy/data/network/model/SubisdyInfoNetworkModel";
import {InfoTypeNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoTypeNetworkModel";
import {InfoTypeResponse} from "@admin/core/network/client/subsidy/model/response/InfoTypeResponse";
import {InfoResponse} from "@admin/core/network/client/subsidy/model/response/InfoResponse";
import {InfoNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoNetworkModel";
import {OkvedDetailResponse} from "@admin/core/network/client/okved/model/OkvedDetailResponse";
import {OkvedDetailNetworkModel} from "@admin/feature/subsidy/data/network/model/OkvedDetailNetworkModel";
import {QuestionNetworkModel} from "@admin/feature/subsidy/data/network/model/QuestionNetworkModel";
import {QuestionResponse} from "@admin/core/network/client/subsidy/model/response/QuestionResponse";
import {AnswerNetworkModel} from "@admin/feature/subsidy/data/network/model/AnswerNetworkModel";
import {AnswerResponse} from "@admin/core/network/client/subsidy/model/response/AnswerResponse";
import {AddSubsidyJuridical} from "@admin/feature/subsidy/domain/model/SubsidyJuridicalFormSchema";
import {AddSubsidyJuridicalRequest} from "@admin/core/network/client/subsidy/model/request/AddSubsidyJuridicalRequest";
import {InfoSubsidyRequest} from "@admin/core/network/client/subsidy/model/request/InfoSubsidyRequest";
import {QuestionVariantRequest} from "@admin/core/network/client/subsidy/model/request/QuestionVariantRequest";
import {QuestionRequest} from "@admin/core/network/client/subsidy/model/request/QuestionRequest";
import {AddSubsidyPhysical} from "@admin/feature/subsidy/domain/model/SubsidyPhysicalFormSchema";
import {AddSubsidyPhysicalRequest} from "@admin/core/network/client/subsidy/model/request/AddSubsidyPhysicalRequest";
import {
    SubsidyJuridicalDetailResponse
} from "@admin/core/network/client/subsidy/model/response/SubsidyJuridicalDetailResponse";
import {
    SubsidyJuridicalDetailNetworkModel
} from "@admin/feature/subsidy/data/network/model/SubsidyJuridicalDetailNetworkModel";
import {OkvedResponse} from "@admin/core/network/client/okved/model/OkvedResponse";
import {OkvedNetworkModel} from "@admin/feature/subsidy/data/network/model/OkvedNetworkModel";
import {AnswerVariantResponse} from "@admin/core/network/client/subsidy/model/response/AnswerVariantResponse";
import {AnswerVariantNetworkModel} from "@admin/feature/subsidy/data/network/model/AnswerVariantNetworkModel";
import {UpdateSubsidyJuridicalModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalModel";
import {
    UpdateSubsidyJuridicalRequest
} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalRequest";
import {UpdateSubsidyJuridicalCommonModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicaCommonlModel";
import {
    UpdateSubsidyJuridicalCommonRequest
} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalCommonRequest";
import {UpdateSubsidyJuridicalInfoModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalInfoModel";
import {UpdateSubsidyJuridicalOkvedModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalOkvedModel";
import {
    UpdateSubsidyJuridicalAnswerVariantModel
} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalAnswerVariantModel";
import {
    UpdateSubsidyJuridicalInfoRequest
} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalInfoRequest";
import {
    UpdateSubsidyJuridicalOkvedRequest
} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalOkvedRequest";
import {
    UpdateSubsidyJuridicalAnswerVariantRequest
} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalAnswerVariantRequest";
import {AnswerVariantRequest} from "@admin/core/network/client/subsidy/model/request/AnswerVariantRequest";

export function toUpdateJuridicalRequest(model: UpdateSubsidyJuridicalModel): UpdateSubsidyJuridicalRequest {
    switch (true) {
        case model instanceof UpdateSubsidyJuridicalCommonModel:
            let data1 = model as UpdateSubsidyJuridicalCommonModel
            return new UpdateSubsidyJuridicalCommonRequest(
                data1.id,
                data1.name,
                data1.isActive,
                data1.isFavorite
            )
        case model instanceof UpdateSubsidyJuridicalInfoModel:
            let data2 = model as UpdateSubsidyJuridicalInfoModel
            return new UpdateSubsidyJuridicalInfoRequest(
                data2.id,
                data2.info.map(it => new InfoSubsidyRequest(it.id, it.value))
            )
        case model instanceof UpdateSubsidyJuridicalOkvedModel:
            let data3 = model as UpdateSubsidyJuridicalOkvedModel
            return new UpdateSubsidyJuridicalOkvedRequest(
                data3.id,
                data3.okved
            )
        case model instanceof UpdateSubsidyJuridicalAnswerVariantModel:
            let data = model as UpdateSubsidyJuridicalAnswerVariantModel
            return new UpdateSubsidyJuridicalAnswerVariantRequest(
                data.id,
                data.answerVariant.map(it =>
                    new AnswerVariantRequest(it.question.map(it =>
                        new QuestionRequest(it.id, it.answer)))
                )
            )
        default:
            throw new Error()
    }
}

export function toSubsidyNetworkModel(model: SubsidyResponse): SubsidyNetworkModel {
    return new SubsidyNetworkModel(
        model.id,
        model.title,
        model.is_active,
        model.is_active,
        toListSubsidyInfoNetworkModel(model.info)
    )
}

export function toSubsidyJuridicalDetailNetworkModel(model: SubsidyJuridicalDetailResponse): SubsidyJuridicalDetailNetworkModel {
    return new SubsidyJuridicalDetailNetworkModel(
        model.id,
        model.name,
        model.is_active,
        model.is_favorite,
        toListSubsidyInfoNetworkModel(model.info),
        toListOkvedNetworkModel(model.okved),
        toListAnswerVariantNetworkModel(model.answer_variant)
    )
}

export function toAnswerVariantNetworkModel(model: AnswerVariantResponse): AnswerVariantNetworkModel {
    return new AnswerVariantNetworkModel(
        model.id,
        toListQuestionNetworkModel(model.question)
    )
}

export function toListAnswerVariantNetworkModel(list: List<AnswerVariantResponse>): List<AnswerVariantNetworkModel> {
    return list.map(it => toAnswerVariantNetworkModel(it))
}

export function toListSubsidyNetworkModel(list: List<SubsidyResponse>): List<SubsidyNetworkModel> {
    return list.map(it => toSubsidyNetworkModel(it))
}

export function toListSubsidyInfoNetworkModel(list: List<SubsidyInfoResponse>): List<SubsidyInfoNetworkModel> {
    return list.map(it => toSubsidyInfoNetworkModel(it))
}

export function toSubsidyInfoNetworkModel(model: SubsidyInfoResponse): SubsidyInfoNetworkModel {
    return new SubsidyInfoNetworkModel(
        model.id,
        model.title,
        model.value,
        toInfoTypeNetworkModel(model.type),
    )
}

export function toInfoTypeNetworkModel(model: InfoTypeResponse): InfoTypeNetworkModel {
    return new InfoTypeNetworkModel(
        model.id,
        model.title,
        model.description,
    )
}

export function toListInfoTypeNetworkModel(list: List<InfoTypeResponse>): List<InfoTypeNetworkModel> {
    return list.map(it => toInfoTypeNetworkModel(it))
}

export function toInfoNetworkModel(model: InfoResponse): InfoNetworkModel {
    return new InfoNetworkModel(
        model.id,
        model.title,
        toInfoTypeNetworkModel(model.type)
    )
}

export function toListInfoNetworkModel(list: List<InfoResponse>): List<InfoNetworkModel> {
    return list.map(it => toInfoNetworkModel(it))
}

export function toOkvedDetailNetworkModel(model: OkvedDetailResponse): OkvedDetailNetworkModel {
    return new OkvedDetailNetworkModel(
        model.id,
        model.name,
        toListOkvedDetailNetworkModel(model.children)
    )
}

export function toListOkvedDetailNetworkModel(list: List<OkvedDetailResponse>): List<OkvedDetailNetworkModel> {
    return list.map(it => toOkvedDetailNetworkModel(it))
}

export function toOkvedNetworkModel(model: OkvedResponse): OkvedNetworkModel {
    return new OkvedNetworkModel(
        model.id,
        model.name,
    )
}

export function toListOkvedNetworkModel(list: List<OkvedResponse>): List<OkvedNetworkModel> {
    return list.map(it => toOkvedNetworkModel(it))
}

export function toQuestionNetworkModel(model: QuestionResponse): QuestionNetworkModel {
    return new QuestionNetworkModel(
        model.id,
        model.name,
        model.is_multiple,
        toListAnswerNetworkModel(model.answer)
    )
}

export function toListQuestionNetworkModel(list: List<QuestionResponse>): List<QuestionNetworkModel> {
    return list.map(it => toQuestionNetworkModel(it))
}

export function toAnswerNetworkModel(model: AnswerResponse): AnswerNetworkModel {
    return new AnswerNetworkModel(
        model.id,
        model.name,
    )
}

export function toListAnswerNetworkModel(list: List<AnswerResponse>): List<AnswerNetworkModel> {
    return list.map(it => toAnswerNetworkModel(it))
}

export function toSubsidyJuridicalRequest(model: AddSubsidyJuridical): AddSubsidyJuridicalRequest {
    return new AddSubsidyJuridicalRequest(
        model.title,
        model.isActive,
        model.isFavorite,
        model.info.map<InfoSubsidyRequest>(it => new InfoSubsidyRequest(it.id, it.value)) as List<InfoSubsidyRequest>,
        model.okved.map<string>(it => it.id) as List<string>,
        model.questionVariant.map(it =>
            new QuestionVariantRequest(
                it.question.map(it =>
                    new QuestionRequest(
                        it.id,
                        it.answer.map<number>(it => it.id) as List<number>
                    )) as List<QuestionRequest>
            )) as List<QuestionVariantRequest>
    )
}

export function toSubsidyPhysicalRequest(model: AddSubsidyPhysical): AddSubsidyPhysicalRequest {
    return new AddSubsidyPhysicalRequest(
        model.title,
        model.isActive,
        model.isFavorite,
        model.info.map<InfoSubsidyRequest>(it => new InfoSubsidyRequest(it.id, it.value)) as List<InfoSubsidyRequest>,
        model.questionVariant.map(it =>
            it.question.map(it =>
                new QuestionRequest(
                    it.id,
                    it.answer.map<number>(it => it.id) as List<number>
                )) as List<QuestionRequest>
        ) as List<List<QuestionRequest>>
    )
}
