import {UpdateSubsidyJuridicalModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalModel";

export class UpdateSubsidyJuridicalCommonModel extends UpdateSubsidyJuridicalModel {

    constructor(
        readonly id: number,
        readonly name: string,
        readonly isActive: boolean,
        readonly isFavorite: boolean,
    ) {
        super(id);
    }
}