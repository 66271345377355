import {SubsidyInteractor} from "@admin/feature/subsidy/domain/SubsidyInteractor";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {SubsidyModel} from "@admin/feature/subsidy/domain/model/SubsidyModel";
import {List} from "@core/list/List";
import {SubsidyRepository} from "@admin/feature/subsidy/data/repository/SubsidyRepository";
import {SubsidyRepositoryImpl} from "@admin/feature/subsidy/data/repository/SubsidyRepositoryImpl";
import {
    toInfoModel,
    toInfoTypeModel, toListInfoModel, toListInfoTypeModel, toListOkvedDetailModel, toListQuestionModel,
    toListSubsidyModel, toSubsidyJuridicalDetailModel,
    toSubsidyModel
} from "@admin/feature/subsidy/domain/DomainMapper";
import {InfoTypeModel} from "@admin/feature/subsidy/domain/model/InfoTypeModel";
import {InfoModel} from "@admin/feature/subsidy/domain/model/InfoModel";
import {OkvedDetailModel} from "@admin/feature/subsidy/domain/model/OkvedDetailModel";
import {QuestionModel} from "@admin/feature/subsidy/domain/model/QuestionModel";
import {
    AddSubsidyJuridical,
    SubsidyJuridicalFormSchema
} from "@admin/feature/subsidy/domain/model/SubsidyJuridicalFormSchema";
import {
    AddSubsidyPhysical,
    SubsidyPhysicalFormSchema
} from "@admin/feature/subsidy/domain/model/SubsidyPhysicalFormSchema";
import {SubsidyJuridicalDetailModel} from "@admin/feature/subsidy/domain/model/SubsidyJuridicalDetailModel";
import {SubsidyInfoModel} from "@admin/feature/subsidy/domain/model/SubsidyInfoModel";
import {UpdateSubsidyJuridicalModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalModel";

export class SubsidyInteractorImpl implements SubsidyInteractor {

    constructor(
        private readonly repository: SubsidyRepository
    ) {
    }

    static init = (): SubsidyInteractor =>
        new SubsidyInteractorImpl(
            SubsidyRepositoryImpl.init()
        )

    getSubsidyJuridicalList = (): ExtPromise<List<SubsidyModel>> =>
        this.repository.getSubsidyJuridicalList()
            .then(it => toListSubsidyModel(it))

    getSubsidyJuridical = (id: number): ExtPromise<SubsidyModel> =>
        this.repository.getSubsidyJuridicalDetail(id)
            .then(it => new SubsidyModel(1, "", false, false, new List<SubsidyInfoModel>()))
    // .then(it => toSubsidyModel(it))

    getSubsidyJuridicalDetail = (id: number): ExtPromise<SubsidyJuridicalDetailModel | null> =>
        this.repository.getSubsidyJuridicalDetail(id)
            .then(it => it ? toSubsidyJuridicalDetailModel(it) : it)

    addSubsidyJuridical(data: object): ExtPromise<SubsidyModel> {
        const validate = SubsidyJuridicalFormSchema.safeParse(data)
        if (!validate.success) {
            throw new Error(validate.error.issues[0].message)
        }

        return this.repository.addSubsidyJuridical(data as AddSubsidyJuridical)
            .then(it => toSubsidyModel(it))
    }

    addSubsidyPhysical(data: object): ExtPromise<SubsidyModel> {
        const validate = SubsidyPhysicalFormSchema.safeParse(data)
        if (!validate.success) {
            throw new Error(validate.error.issues[0].message)
        }

        return this.repository
            .addSubsidyPhysical(data as AddSubsidyPhysical)
            .then(it => toSubsidyModel(it))
    }

    getPhysicalInfoList = (): ExtPromise<List<InfoModel>> =>
        this.repository.getPhysicalInfoList()
            .then(it => toListInfoModel(it))

    getJuridicalInfoList = (): ExtPromise<List<InfoModel>> =>
        this.repository.getJuridicalInfoList()
            .then(it => toListInfoModel(it))

    getInfoTypeList = (): ExtPromise<List<InfoTypeModel>> =>
        this.repository.getInfoTypeList()
            .then(it => toListInfoTypeModel(it))

    getOkvedList = (): ExtPromise<List<OkvedDetailModel>> =>
        this.repository.getOkvedList()
            .then(it => toListOkvedDetailModel(it))

    getQuestionJuridicalList = (): ExtPromise<List<QuestionModel>> =>
        this.repository.getQuestionJuridicalList()
            .then(it => toListQuestionModel(it))

    getQuestionPhysicalList = (): ExtPromise<List<QuestionModel>> =>
        this.repository.getQuestionPhysicalList()
            .then(it => toListQuestionModel(it))

    updateSubsidyJuridical = (data: UpdateSubsidyJuridicalModel): ExtPromise<boolean> =>
        this.repository.updateSubsidyJuridical(data)
}