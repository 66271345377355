import {SubsidyClient} from "@admin/core/network/client/subsidy/SubsidyClient";
import {BaseClient} from "@core/network/builder/BaseClient";
import {SubsidyResponse} from "@admin/core/network/client/subsidy/model/response/SubsidyResponse";
import {InfoTypeResponse} from "@admin/core/network/client/subsidy/model/response/InfoTypeResponse";
import {InfoResponse} from "@admin/core/network/client/subsidy/model/response/InfoResponse";
import {QuestionResponse} from "@admin/core/network/client/subsidy/model/response/QuestionResponse";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {List} from "@core/list/List";
import {AddSubsidyJuridicalRequest} from "@admin/core/network/client/subsidy/model/request/AddSubsidyJuridicalRequest";
import {AddSubsidyPhysicalRequest} from "@admin/core/network/client/subsidy/model/request/AddSubsidyPhysicalRequest";
import {
    SubsidyJuridicalDetailResponse
} from "@admin/core/network/client/subsidy/model/response/SubsidyJuridicalDetailResponse";
import {
    UpdateSubsidyJuridicalRequest
} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalRequest";

export class SubsidyClientImpl extends BaseClient implements SubsidyClient {

    getSubsidyPhysicalList = (): ExtPromise<List<SubsidyResponse>> =>
        this.get<List<SubsidyResponse>>("/subsidy/physical")

    getSubsidyJuridical = (id: number): ExtPromise<SubsidyResponse> =>
        this.get<SubsidyResponse>("/subsidy/juridical?id=" + id)

    getSubsidyJuridicalDetail = (id: number): ExtPromise<SubsidyJuridicalDetailResponse | null> =>
        this.get<SubsidyJuridicalDetailResponse | null>("/subsidy/juridical/detail?id=" + id)

    getSubsidyJuridicalList = (): ExtPromise<List<SubsidyResponse>> =>
        this.get<List<SubsidyResponse>>("/subsidy/juridical/list")

    getPhysicalInfoList = (): ExtPromise<List<InfoResponse>> =>
        this.get<List<InfoResponse>>("/subsidy/physical/info/list")

    getJuridicalInfoList = (): ExtPromise<List<InfoResponse>> =>
        this.get<List<InfoResponse>>("/subsidy/juridical/info/list")

    getInfoTypeList = (): ExtPromise<List<InfoTypeResponse>> =>
        this.get<List<InfoTypeResponse>>("/subsidy/info/type/list")

    getInfo = (): ExtPromise<InfoResponse> =>
        this.get<InfoResponse>("/subsidy/info")

    getInfoType = (): ExtPromise<InfoTypeResponse> =>
        this.get<InfoTypeResponse>("/subsidy/info/type")

    addSubsidyJuridical = (request: AddSubsidyJuridicalRequest): ExtPromise<SubsidyResponse> =>
        this.post<SubsidyResponse>("/subsidy/juridical", request)

    addSubsidyPhysical = (request: AddSubsidyPhysicalRequest): ExtPromise<SubsidyResponse> =>
        this.post<SubsidyResponse>("/subsidy/physical", request)

    getQuestionJuridicalList = (): ExtPromise<List<QuestionResponse>> =>
        this.get<List<QuestionResponse>>("/subsidy/juridical/question/list")

    getQuestionPhysicalList = (): ExtPromise<List<QuestionResponse>> =>
        this.get<List<QuestionResponse>>("/subsidy/physical/question/list")

    updateSubsidyJuridical = (request: UpdateSubsidyJuridicalRequest): ExtPromise<boolean> =>
        this.put<boolean>("/subsidy/juridical", request)
}