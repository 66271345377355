import {Link} from "react-router-dom";
import React, {DetailedHTMLProps, HTMLAttributes} from "react";
import {UserModel} from "@admin/feature/user/list/_component/model/UserModel";
import {DealModel} from "./model/DealModel";

interface DealItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    deal: DealModel
}

export function DealItem(props: DealItemProps) {

    const {deal, ...divProps} = props

    return (
        <div {...divProps}>
            <Link to={`#`} className="flex flex-col m-1 bg-white shadow-lg rounded-lg p-4
                hover:bg-blue-300
                active:bg-blue-200
                cursor-pointer"
                  id={String(deal.id)}>
                {/*<div className="flex-none w-14 h-14">{subsidy.id}</div>*/}
                <div className="flex-grow"><label className="font-bold">Название:</label> {deal.title}</div>
                <div className="flex-grow"><label className="font-bold">Описание:</label> {deal.description}</div>
                <div className="flex-initial self-center">{}</div>
            </Link>
        </div>
    );
}