import {UpdateSubsidyJuridicalRequest} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalRequest";
import {List} from "@core/list/List";
import {AnswerVariantRequest} from "@admin/core/network/client/subsidy/model/request/AnswerVariantRequest";

export class UpdateSubsidyJuridicalAnswerVariantRequest extends UpdateSubsidyJuridicalRequest {

    constructor(
        readonly id: number,
        readonly answer_variant: List<AnswerVariantRequest>,
    ) {
        super(id);
    }
}