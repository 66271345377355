import {LayoutPage} from "@core/ui/layout/LayoutPage";
import {Form} from "@core/form/Form";
import {FormInput} from "@core/form/input/FormInput";
import {CheckBox} from "@core/ui/checkbox/CheckBox";
import {FormFieldSet} from "@core/form/fieldset/FormFieldSet";
import {FormSelectSubsidyInfo} from "@admin/feature/subsidy/ui/form/FormSelectSubsidyInfo";
import {FormSelectOkved} from "@admin/feature/subsidy/ui/form/FormSelectOkved";
import {FormSelectQuestionVariant} from "@admin/feature/subsidy/ui/form/FormSelectQuestionVariant";
import {FormSubmit} from "@core/form/submit/FormSubmit";
import {Logger} from "@core/logger/Logger";
import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import {useAppContext} from "@app/context/AppContextProvider";
import {useState} from "react";
import {AddSubsidyJuridical} from "@admin/feature/subsidy/domain/model/SubsidyJuridicalFormSchema";

export function JuridicalAddPage() {

    const context = useAppContext()
    const interactor = SubsidyInteractorImpl.init()

    const [saving,setSaving] = useState(false)

    function onSuccessSerialize(object: object) {
        setSaving(true)

        interactor.addSubsidyJuridical(object)
            .then(it=> context.snackbar.show("Субсидия добавлена"))
            .catch(error =>context.snackbar.showError(error))
            .finally(()=>setSaving(false))
    }

    return (
        <LayoutPage>
            <div className="flex flex-col items-center p-4 gap-y-2">
                <div>Добавление субсидии</div>
                <Form<AddSubsidyJuridical> className="flex flex-col w-full gap-y-2"
                      onSuccessSerialize={it => onSuccessSerialize(it)}>

                    <div className="flex flex-col">
                        <div>Название субсидии</div>
                        <FormInput name="title"/>
                    </div>

                    <div className="flex flex-row">
                        <CheckBox name="isActive" defaultChecked={true}/>
                        <div>Видимость</div>
                    </div>

                    <div className="flex flex-row">
                        <CheckBox name="isFavorite" defaultChecked={false}/>
                        <div>Избранная</div>
                    </div>

                    <FormSelectSubsidyInfo/>
                    <FormSelectOkved/>
                    <FormSelectQuestionVariant/>
                    <FormSubmit/>
                </Form>
            </div>
        </LayoutPage>
    )
}
