import {API_KEY} from "../../../config.js";
import {BaseClient} from "../../builder/BaseClient";
import {UserStorage} from "../../../core/storage/user/UserStorage";
import {DealClient} from "./DealClient";
import {DealNetEntity} from "./model/DealNetEntity";

export class DealClientImpl extends BaseClient implements DealClient {
    protected userStorage: UserStorage

    constructor(userStorage: UserStorage) {
        super(
            new Headers({
                'Host': 'https://www.kosmosmarketing.ru',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${
                    userStorage.getToken()?.access ?? API_KEY}`,
            })
        )
        this.userStorage = userStorage
    }

    getDeal(idDeal: number): Promise<DealNetEntity> {
        return this.get<DealNetEntity>(`/deal/${idDeal}`);
    }

    getDealList(page: number): Promise<DealNetEntity[]> {
        return this.get<DealNetEntity[]>(`/deal/list/${page}`);
    }

}