import React, {ReactEventHandler, RefObject, SyntheticEvent} from "react";
import {FormInputProps, FormInputState} from "./form-input-settings";

type Props = Readonly<FormInputProps>
type State = Readonly<FormInputState>

export class FormInput extends React.Component<Props, State> {

    public static readonly defaultProps: Props = {}

    public readonly state: State = {}

    private readonly ref: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>()


    private readonly onSubmit: ReactEventHandler<HTMLInputElement> = (event: SyntheticEvent<HTMLInputElement>) => {
        event.preventDefault()
    }

    render() {
        return <div className="flex rounded-lg border p-1 w-full h-full">
            <input className="w-full h-full" ref={this.ref} {...this.props}/>
        </div>
    };
}