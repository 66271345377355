export function IconPlus() {

    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M5 12H19" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"></path>
                <path d="M12 5L12 19" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"></path>
            </g>
        </svg>
    );
}