export class JsonUtils {

    static isJson(value: any): Boolean {
        if (typeof value !== "string" && value) {
            return false
        }

        try {
            let val = JSON.parse(value)
            return val instanceof Array || val instanceof Object;
        } catch (error) {
            return false
        }
    }
}
