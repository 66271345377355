import {useEffect, useState} from "react";
import {UserStorage} from "@core/storage/user/UserStorage";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";

export function ProfileDropdown() {

    const userStorage: UserStorage = new UserStorageImpl()
    const [fullName, setFullName] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    function getFullName() {
        const profile = userStorage.getProfile()
        setFullName(profile?.surname ?? "" + " " + profile?.name ?? "")
    }

    function onClickSignOut() {
        userStorage.clearAll()
        window.location.replace("/")
    }

    useEffect(() => {
        getFullName()
    }, []);

    return (
        <div className="flex w-auto h-auto">
            <div className="flex-initial ml-auto self-center mr-2">
                <div>{fullName}</div>
            </div>
            <div className="flex-initial">
                <button type="button"
                        onClick={() => setIsOpen(!isOpen)}
                        onBlur={() => setIsOpen(false)}
                        className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full"
                         src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                         alt=""/>
                </button>

                {isOpen && (
                    <div
                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                        <div className="py-1" role="none">
                            {/*<a href="src/core-ui/component#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"*/}
                            {/*   tabIndex={-1} id="menu-item-0">Account settings</a>*/}
                            {/*<a href="src/core-ui/component#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"*/}
                            {/*   tabIndex={-1} id="menu-item-1">Support</a>*/}
                            {/*<a href="src/core-ui/component#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"*/}
                            {/*   tabIndex={-1} id="menu-item-2">License</a>*/}
                            <button
                                className="text-gray-700 block w-full px-4 py-2 text-left text-sm
                            hover:bg-primary-200
                            active:bg-primary-300"
                                onClick={onClickSignOut}>Выйти
                            </button>
                            {/*<form method="POST" action="src/core-ui/component#" role="none">*/}
                            {/*    <button type="submit"*/}
                            {/*            className="text-gray-700 block w-full px-4 py-2 text-left text-sm"*/}
                            {/*            role="menuitem" tabIndex={-1} id="menu-item-3">Sign out*/}
                            {/*    </button>*/}
                            {/*</form>*/}
                        </div>
                    </div>)
                }
            </div>
        </div>
    );
}