import {BaseClient} from "@core/network/builder/BaseClient";
import {OkvedClient} from "@admin/core/network/client/okved/OkvedClient";
import {OkvedDetailResponse} from "@admin/core/network/client/okved/model/OkvedDetailResponse";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {List} from "@core/list/List";

export class OkvedClientImpl extends BaseClient implements OkvedClient {

    getOkved = (): ExtPromise<OkvedDetailResponse> =>
        this.get<OkvedDetailResponse>("/okved")

    getOkvedList = (): ExtPromise<List<OkvedDetailResponse>> =>
        this.get<List<OkvedDetailResponse>>("/okved/list")

}