import {List} from "@core/list/List";
import {InfoSubsidyRequest} from "@admin/core/network/client/subsidy/model/request/InfoSubsidyRequest";
import {QuestionVariantRequest} from "@admin/core/network/client/subsidy/model/request/QuestionVariantRequest";
import {OkvedRequest} from "@admin/core/network/client/subsidy/model/request/OkvedRequest";
import {QuestionRequest} from "@admin/core/network/client/subsidy/model/request/QuestionRequest";

export class AddSubsidyJuridicalRequest {

    constructor(
        public readonly title: string,
        public readonly is_active: boolean,
        public readonly is_favorite: boolean,
        public readonly info: List<InfoSubsidyRequest>,
        public readonly okved: List<string>,
        public readonly question_variant: List<QuestionVariantRequest>,
    ) {
    }
}