import {BaseClient} from "../../builder/BaseClient";
import {PhoneClient} from "./PhoneClient";
import {NetworkError} from "../../builder/response/NetworkError";
import {CompanyPhoneNetworkModel} from "./model/CompanyPhoneNetworkModel";
import {UserStorage} from "@core/storage/user/UserStorage";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";

export class PhoneClientImpl extends BaseClient implements PhoneClient {
    constructor() {
        const userStorage: UserStorage = new UserStorageImpl()

        super(
            new Headers({
                'Host': 'https://www.kosmosmarketing.ru',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userStorage.getToken()?.access}`,
            })
        );
    }

    getPhone(
        onSuccess: (response: CompanyPhoneNetworkModel[]) => void,
        onError: (error: NetworkError) => void
    ): void {
        this.get<CompanyPhoneNetworkModel[]>("/company/phone/list")
            .then(response => onSuccess(response))
            .catch(error => onError(error))
    }

    setPhone(
        phone: string,
        onSuccess: (response: Boolean) => void,
        onError: (error: NetworkError) => void
    ): void {
        this.post<Boolean>("/company/phone", {"phone": phone})
            .then(response => onSuccess(response))
            .catch(error => onError(error))
    }

    deletePhone(
        idPhone: number,
        onSuccess: (response: Boolean) => void,
        onError: (error: NetworkError) => void
    ): void {
        this.delete<Boolean>("/company/phone", {"id_phone": idPhone})
            .then(response => onSuccess(response))
            .catch(error => onError(error))
    }


}