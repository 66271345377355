import {Background} from "@anon/core/ui/background/Background";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {FeatureAuth} from "@anon/feature/auth/FeatureAuth";
import React, {useState} from "react";
import {LogoIcon} from "@core/ui/image/LogoIcon";
import {LoginInputField} from "@anon/core/ui/inputField/LoginInputField";
import {PasswordInputField} from "@anon/core/ui/inputField/PasswordInputField";
import {CheckBox} from "@core/ui/checkbox/CheckBox";
import {ButtonPrimary} from "@core/ui/button/ButtonPrimary";
import {TextLink} from "@core/ui/text/TextLink";

export function FeatureSupport() {

    const navigate = useNavigate();
    const [phone, setPhone] = useState('+7');

    return (

        <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
            <Background/>
            <div className="relative px-6 pt-10 pb-8
        sm:bg-white sm:shadow-xl sm:ring-1 sm:ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
                <div className="flex flex-col py-4 px-4sm:px-6 lg:px-8 items-center justify-center min-h-full">
                    <div className="m-5">
                        <LogoIcon height={128} width={128}/>
                    </div>
                    <div>
                        <h2 className="mb-6 text-center text-3xl font-bold tracking-tight text-gray-900">{"Форма удаления аккаунта"}</h2>
                    </div>
                    <div className="max-w-sm w-full">

                        <input id="phone"
                               name="phone"
                               type="tel"
                               required
                               value={phone}
                               className="relative block w-full appearance-none rounded-md border
                                             border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10
                                             focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                               placeholder="Номер телефона"
                               onChange={(event) => {
                                   const value = event.target.value;
                                   // Фильтруем ввод, разрешая только цифры
                                   const formattedValue = value.replace(/[^0-9]/g, '').slice(1, 11);
                                   setPhone(`+7${formattedValue}`);
                               }}/>
                    </div>
                    <div className="mt-5 w-full">
                        <ButtonPrimary label={"Отправить"} onClick={() => {
                            if (phone.length == 12) {
                                alert("Запрос отправлен, в течении 1-2 дней мы с вами свяжемся, чтобы удостовериться в вашей личности")
                                navigate("/")
                            } else {
                                alert("Введите корректный номер")
                            }
                        }
                        }/>
                    </div>
                </div>
            </div>
        </div>
    );
}