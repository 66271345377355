import {List} from "@core/list/List";
import {OkvedModel} from "@admin/feature/subsidy/domain/model/OkvedModel";

export class OkvedDetailModel extends OkvedModel {

    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly children: List<OkvedDetailModel>
    ) {
        super(id, name);
    }
}