import {NetworkProvider} from "@admin/core/network/NetworkProvider";
import {NetworkBuilder} from "@core/network/builder/NetworkBuilder";
import {NetworkBuilderClient} from "@admin/core/network/builder/NetworkBuilderClient";
import {SubsidyClient} from "@admin/core/network/client/subsidy/SubsidyClient";
import {SubsidyClientImpl} from "@admin/core/network/client/subsidy/SubsidyClientImpl";
import {OkvedClient} from "@admin/core/network/client/okved/OkvedClient";
import {OkvedClientImpl} from "@admin/core/network/client/okved/OkvedClientImpl";

export class NetworkProviderImpl implements NetworkProvider {

    constructor(
        readonly builder: NetworkBuilder
    ) {
    }

    static init = (): NetworkProvider =>
        new NetworkProviderImpl(
            NetworkBuilderClient.init()
        )

    getSubsidyClient = (): SubsidyClient =>
        this.builder.create(SubsidyClientImpl)

    getOkvedClient = (): OkvedClient =>
        this.builder.create(OkvedClientImpl)

}