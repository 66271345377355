import {API_KEY} from "../../../config.js";
import {BaseClient} from "../../builder/BaseClient";
import {SubsidyClient} from "./SubsidyClient";
import {NetworkError} from "../../builder/response/NetworkError";
import {SubsidyArrayResponse} from "./response/SubsidyArrayResponse";
import {UserStorage} from "@core/storage/user/UserStorage";
import {SubsidyQuestionDetailNetworkModel} from "./model/SubsidyQuestionDetailNetworkModel";
import {AddQuestionRequest} from "./request/AddQuestionRequest";
import {AnswerAddRequest} from "./request/AnswerAddRequest";
import {SubsidyQuestionAddNetworkModel} from "./model/SubsidyQuestionAddNetworkModel";
import {QuestionConditionResponse} from "./response/QuestionConditionResponse";
import {AddQuestionConditionRequest} from "./request/AddQuestionConditionRequest";
import {AddQuestionAdditionRequest} from "./request/AddQuestionAdditionRequest";
import {InfoNetEntity} from "./model/InfoNetEntity";
import {InfoTypeNetEntity} from "./model/InfoTypeNetEntity";
import {AddInfoRequest} from "./request/AddInfoRequest";
import {SubsidyInfoNetEntity} from "./model/SubsidyInfoNetEntity";
import {SubsidyDetailNetEntity} from "./model/SubsidyDetailNetEntity";
import {EditSubsidyAnswerNetRequest} from "./request/EditSubsidyAnswerNetRequest";

export class SubsidyClientImpl extends BaseClient implements SubsidyClient {
    protected userStorage: UserStorage

    constructor(userStorage: UserStorage) {
        super(
            new Headers({
                'Host': 'https://www.kosmosmarketing.ru',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userStorage.getToken()?.access ?? API_KEY}`,
            })
        )
        this.userStorage = userStorage
    }

    deleteQuestionCondition(idCondition: number): Promise<Boolean> {
        return this.delete<Boolean>(`/subsidy/question/condition/${idCondition}`)
    }

    editQuestionCondition(idCondition: number, idsAnswer: number[]): Promise<Boolean> {
        return this.put<Boolean>(`/subsidy/question/condition/${idCondition}`, {ids_answer: idsAnswer})
    }

    addQuestionCondition(idQuestionMain: number, idQuestion: number, idAnswers: number[]): Promise<Boolean> {
        const request = new AddQuestionConditionRequest(idQuestion, idAnswers)
        return this.post<Boolean>(`/subsidy/question/${idQuestionMain}/condition`, request)
    }

    addQuestionAddition(request: AddQuestionAdditionRequest): Promise<Boolean> {
        return this.post<Boolean>(`/subsidy/question/${request.id_question}/addition`, request)
    }


    getSubsidyArray(
        page: number,
        onSuccess: (response: SubsidyArrayResponse) => void,
        onError: (error: NetworkError) => void
    ): void {
        this.userStorage.getProfile()
        this.get<SubsidyArrayResponse>(`/subsidy/list/${page}`)
            .then(response => onSuccess(response))
            .catch(error => onError(error))
    }

    getSubsidy(id: number): Promise<SubsidyArrayResponse> {
        return this.get<SubsidyArrayResponse>(`/subsidy/${id}`)
    }

    getSubsidyDetailed(id: number): Promise<SubsidyDetailNetEntity> {
        return this.get<SubsidyDetailNetEntity>(`/subsidy/${id}/detail`)
    }


    getInfo(): Promise<InfoNetEntity[]> {
        return this.get<InfoNetEntity[]>(`/subsidy/info/list`)
    }

    getQuestion(
        onSuccess: (response: Array<SubsidyQuestionDetailNetworkModel>) => void,
        onError: (error: NetworkError) => void
    ): void {
        this.get<Array<SubsidyQuestionDetailNetworkModel>>(`/subsidy/question/list`)
            .then(response => onSuccess(response))
            .catch(error => onError(error))
    }

    getQuestionList(): Promise<SubsidyQuestionDetailNetworkModel[]> {
        return this.get<SubsidyQuestionDetailNetworkModel[]>(`/subsidy/question/list`)
    }


    getQuestionById(
        idQuestion: number
    ): Promise<SubsidyQuestionDetailNetworkModel> {
        return this.get<SubsidyQuestionDetailNetworkModel>(`/subsidy/question/${idQuestion}`)
    }

    addQuestion(
        request: AddQuestionRequest,
        onSuccess: (response: Boolean) => void,
        onError: (error: NetworkError) => void
    ): void {
        this.post<Boolean>(`/subsidy/question`, request)
            .then(response => onSuccess(response))
            .catch(error => onError(error))
    }

    deleteQuestion(idQuestion: number): Promise<Boolean> {
        return this.delete<Boolean>(`/subsidy/question/${idQuestion}`)
    }

    setAnswer(idQuestion: number, answers: string[]): Promise<Boolean> {
        const request = new AnswerAddRequest(answers)
        return this.post<Boolean>(`/subsidy/question/${idQuestion}/answer`, request)
    }

    deleteAnswer(idAnswer: number): Promise<Boolean> {
        return this.delete<Boolean>(`/subsidy/question/answer`, {"id_answer": idAnswer})
    }

    getQuestionAdditional(idQuestion: number): Promise<SubsidyQuestionAddNetworkModel> {
        return this.get<SubsidyQuestionAddNetworkModel>(`/subsidy/question/additional/${idQuestion}`);
    }

    getQuestionConditional(idQuestion: number): Promise<QuestionConditionResponse> {
        return this.get<QuestionConditionResponse>(`/subsidy/question/${idQuestion}/condition`);
    }

    getInfoList(): Promise<InfoNetEntity[]> {
        return this.get<InfoNetEntity[]>(`/subsidy/info/list`);
    }

    getInfoById(idInfo: number): Promise<InfoNetEntity> {
        return this.get<InfoNetEntity>(`/subsidy/info/${idInfo}`);
    }

    getInfoTypeList(): Promise<InfoTypeNetEntity[]> {
        return this.get<InfoTypeNetEntity[]>(`/subsidy/info/type/list`)
    }

    setInfo(title: string, idType: number): Promise<Boolean> {
        const request = new AddInfoRequest(title, idType)
        return this.post<Boolean>(`/subsidy/info`, request);
    }

    deleteInfo(id: number): Promise<Boolean> {
        return this.delete<Boolean>(`/subsidy/info`, {id: id})
    }

    deleteInfoType(id: number): Promise<Boolean> {
        return this.delete<Boolean>(`/subsidy/info/type`, {id: id})
    }

    getInfoTypeById(id: number): Promise<InfoTypeNetEntity> {
        return this.get<InfoTypeNetEntity>(`/subsidy/info/type/${id}`);
    }

    setAnswerVariadic(data: { id_question: number; id_answers: number[] }[]): Promise<Boolean> {
        return this.post<Boolean>(`/subsidy/answer`, {variadic: data})
    }

    setSubsidy(request: {
        title: string;
        is_active: boolean;
        info: Array<{ id_info: number; value: string }>;
        answers_variant: Array<Array<number>>
    }): Promise<Boolean> {
        return this.post<Boolean>(`/subsidy`, request);
    }

    getSubsidyInfo(idSubsidy: number): Promise<SubsidyInfoNetEntity> {
        return this.get<SubsidyInfoNetEntity>(`subsidy/${idSubsidy}/info`)
    }

    editSubsidyMainInfo(idSubsidy: number, title: string, isActive: boolean): Promise<Boolean> {
        return this.put<Boolean>(`/subsidy/${idSubsidy}/main-info`, {title: title, is_active: isActive})
    }

    editSubsidyInfo(idSubsidy: number, info: Array<{ id: number; value: string }>): Promise<Boolean> {
        return this.put<Boolean>(`/subsidy/${idSubsidy}/info`, {info: info})
    }

    editSubsidyAnswer(idSubsidy: number, request: EditSubsidyAnswerNetRequest): Promise<any> {
        return this.put<any>(`/subsidy/${idSubsidy}/answer`, request.serialize())
    }

    editInfo(idInfo: number, title: string, idInfoType: number): Promise<any> {
        return this.put<any>(`/subsidy/info`, {id: idInfo, title: title, id_info_type: idInfoType})
    }

    addInfoType(title: string, description: string): Promise<any> {
        return this.post<any>(`/subsidy/info/type`, {title: title, description: description})
    }

    editInfoType(idInfoType: number, title: string, description: string): Promise<any> {
        return this.put<any>(`/subsidy/info/type`, {id: idInfoType, title: title, description: description})
    }

}