import React, {ReactNode, SyntheticEvent} from "react";
import {FormSerialize} from "./form-serialize";
import {FormError} from "@core/form/form-settings";

interface FormProps<T> {
    className?: string
    children?: ReactNode
    error?: Array<FormError>
    onErrorSerialize?: () => void
    onSuccessSerialize?: (objectForm: T) => void
}

export function Form<T>(props: FormProps<T>) {

    const defFormProps = {className: props.className, children: props.children}

    function onSubmit(event: SyntheticEvent<HTMLFormElement>) {
        event.preventDefault()
        console.log(event)
        const object = FormSerialize.serialize(event) as T
        console.log(object)
        props.onSuccessSerialize?.(object)
    }

    function onReset(event: SyntheticEvent<HTMLFormElement>) {
        event.currentTarget.reset()
    }

    return (<form {...defFormProps} onSubmit={onSubmit}/>)
}