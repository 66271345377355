import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import React, {useEffect, useState} from "react";
import {List} from "@core/list/List";
import {SubsidyModel} from "@admin/feature/subsidy/domain/model/SubsidyModel";
import {useAppContext} from "@app/context/AppContextProvider";
import {JuridicalItem} from "@admin/feature/subsidy/ui/item/JuridicalItem";
import {Route, Routes, useNavigate} from "react-router-dom";
import {JuridicalDetailPage} from "@admin/feature/subsidy/ui/page/JuridicalDetailPage";
import {ButtonFloating_v2} from "@core/ui/button/ButtonFloating_v2";
import {JuridicalAddPage} from "@admin/feature/subsidy/ui/page/JuridicalAddPage";

export function JuridicalList() {

    const context = useAppContext()
    const navigate = useNavigate()
    const interactor = SubsidyInteractorImpl.init()
    const [subsidyList, setSubsidyList] = useState<List<SubsidyModel>>()

    function onClickItem(item: SubsidyModel) {
        navigate(item.id.toString())
    }

    function onClickAdd(){
        navigate("add")
    }

    useEffect(() => {
        interactor.getSubsidyJuridicalList()
            .then(it => setSubsidyList(it))
            .catch(error => context.snackbar.showError(error))
    }, []);

    return (
        <div className="flex flex-col gap-y-2 m-1 h-full overflow-y-auto">
            {subsidyList?.map(it => <JuridicalItem key={it.id} item={it} onClickItem={() => onClickItem(it)}/>)}
            <Routes>
                <Route path="add" element={<JuridicalAddPage/>}/>
                <Route path=":id" element={<JuridicalDetailPage/>}/>
            </Routes>
            <ButtonFloating_v2 onClick={onClickAdd} icon={<div>+</div>} />
        </div>
    )
}