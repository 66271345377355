import {useParams} from "react-router-dom";
import {ReactNode, useEffect, useState} from "react";
import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import {useAppContext} from "@app/context/AppContextProvider";
import {Logger} from "@core/logger/Logger";
import {isNumber, toNumber} from "@core/extension/Extensions";
import {LayoutPage} from "@core/ui/layout/LayoutPage";
import {Form} from "@core/form/Form";
import {FormInput} from "@core/form/input/FormInput";
import {CheckBox} from "@core/ui/checkbox/CheckBox";
import {FormSelectOkved} from "@admin/feature/subsidy/ui/form/FormSelectOkved";
import {FormSelectQuestionVariant} from "@admin/feature/subsidy/ui/form/FormSelectQuestionVariant";
import {FormSubmit} from "@core/form/submit/FormSubmit";
import {FormChangeSubsidyInfo} from "@admin/feature/subsidy/ui/form/FormChangeSubsidyInfo";
import {SubsidyJuridicalDetailModel} from "@admin/feature/subsidy/domain/model/SubsidyJuridicalDetailModel";
import {InfoModel} from "@admin/feature/subsidy/domain/model/InfoModel";
import {FormChangeSubsidyCommon} from "@admin/feature/subsidy/ui/form/FormChangeSubsidyCommon";
import {FormChangeSubsidyOkved} from "@admin/feature/subsidy/ui/form/FormChangeSubsidyOkved";
import {FormChangeQuestionVariant} from "@admin/feature/subsidy/ui/form/FormChangeQuestionVariant";

export function JuridicalDetailPage() {

    let {id} = useParams();
    const context = useAppContext()
    const interactor = SubsidyInteractorImpl.init()
    const [loading, setLoading] = useState(true)
    const [subsidy, setSubsidy] = useState<SubsidyJuridicalDetailModel>()
    const [inCorrectId, setInCorrectId] = useState(true)
    const [saving, setSaving] = useState(false)

    function onSuccessSerialize(object: object) {
        setSaving(true)

        interactor.addSubsidyJuridical(object)
            .then(it => context.snackbar.show("Субсидия добавлена"))
            .catch(error => context.snackbar.showError(error))
            .finally(() => setSaving(false))
    }

    useEffect(() => {
        if (isNumber(id)) {
            interactor.getSubsidyJuridicalDetail(toNumber(id))
                .then(it => it ? setSubsidy(it) : it)
                .catch(error => context.snackbar.showError(error))
                .finally(() => {
                    setInCorrectId(false)
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }, []);

    useEffect(() => {
    }, [subsidy]);

    return (
        <LayoutPage>
            {
                loading ? <div>Загрузка</div> :
                    inCorrectId ? <div>Не корректный id</div> :
                        subsidy ?
                            <div className="flex flex-col items-center p-4 gap-y-2">
                                <div>Изменение субсидии</div>
                                <FormChangeSubsidyCommon id={subsidy.id} defValue={{
                                    name: subsidy.name,
                                    isActive: subsidy.isActive,
                                    isFavorite: subsidy.isFavorite
                                }}/>

                                <FormChangeSubsidyInfo id={subsidy.id} defValue={subsidy.info.map(it => {
                                    return {info: it, value: it.value}
                                })}/>
                                <FormChangeSubsidyOkved id={subsidy.id} defValue={subsidy.okved}/>
                                <FormChangeQuestionVariant id={subsidy.id} defValue={subsidy.answerVariant}/>
                            </div> : null
            }
        </LayoutPage>
    )
}