import {NetworkProvider} from "./NetworkProvider";
import {AuthClient} from "./client/auth/AuthClient";
import {SubsidyClientImpl} from "./client/subsidy/SubsidyClientImpl";
import {SubsidyClient} from "./client/subsidy/SubsidyClient";
import {PhoneClient} from "./client/phone/PhoneClient";
import {PhoneClientImpl} from "./client/phone/PhoneClientImpl";
import {UserStorage} from "@core/storage/user/UserStorage";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";
import {UserClient} from "./client/user/UserClient";
import {UserClientImpl} from "./client/user/UserClientImpl";
import {DealClient} from "./client/deal/DealClient";
import {DealClientImpl} from "./client/deal/DealClientImpl";

export class NetworkProviderImpl implements NetworkProvider {

    protected userStorage: UserStorage = new UserStorageImpl()

    getAuthClient(): AuthClient {
        return new AuthClient()
    }

    getSubsidyClient(): SubsidyClient {
        return new SubsidyClientImpl(this.userStorage)
    }

    getCompanyClient(): PhoneClient {
        return new PhoneClientImpl()
    }

    getUserClient(): UserClient {
        return new UserClientImpl(this.userStorage)
    }

    getDealClient(): DealClient {
        return new DealClientImpl(this.userStorage)
    }

}