import {Route, Routes} from "react-router-dom";
import React from "react";
import {AdminMainScreen} from "@admin/core/ui/screen-main/AdminMainScreen";
import {MainBackground} from "@admin/core/ui/component/background/MainBackground";
import {AdminNavbar} from "@admin/core/ui/component/navbar/AdminNavbar";
import {ProfileDropdown} from "@core/ui/dropdown/ProfileDropdown";
import {FeatureUser} from "./feature/user/FeatureUser";
import {FeatureAnalytic} from "./feature/analytic/FeatureAnalytic";
import {FeatureNews} from "./feature/news/FeatureNews";
import {FeatureIntegration} from "./feature/integration/FeatureIntegration";
import {FeatureSubsidy} from "./feature/subsidy/FeatureSubsidy";
import {FeatureDeal} from "./feature/deal/FeatureDeal";
import {FeatureAntispam} from "./feature/antispam/FeatureAntispam";
import {_404Page} from "@core/ui/pages/404/_404Page";

export function AppAdmin() {

    return (
        <MainBackground>
            <div className="flex h-screen w-screen overflow-hidden">
                <div className="flex-none bg-gray-50 shadow-xl rounded-lg mt-6 ml-6 mb-6 mr-2 p-2">
                    <AdminNavbar/>
                </div>
                <div className="flex-1 shadow-xl rounded-lg mt-6 mb-6 mr-6 p-2 bg-gray-50">
                    <div className="flex flex-col h-full">
                        <div className="ml-auto">
                            <ProfileDropdown/>
                        </div>
                        <div className="flex h-full overflow-y-auto mt-2">

                            <Routes>
                                <Route path="/" element={<AdminMainScreen/>}/>

                                <Route path="/profile" element={null}/>

                                <Route path="/analytic" element={<FeatureAnalytic/>}/>

                                <Route path="/integration" element={<FeatureIntegration/>}/>

                                {/*<Route path="/subsidy/question/list" element={<AdminQuestionListScreen/>}/>*/}
                                {/*<Route path="/subsidy/question/:id/edit" element={<AdminSubsidyQuestionScreen/>}/>*/}
                                {/*<Route path="/subsidy/:id/edit" element={<AdminSubsidyScreen/>}/>*/}
                                {/*<Route path="/subsidy/:id" element={<AdminSubsidyScreen/>}/>*/}
                                {/*<Route path="/subsidy/add" element={<AdminSubsidyAddScreen/>}/>*/}
                                {/*<Route path="/subsidy/list/:page?" element={<AdminSubsidyListScreen/>}/>*/}
                                {/*<Route path="/subsidy/info/list/:page?" element={<AdminSubsidyInfoList/>}/>*/}
                                {/*<Route path="/subsidy/info/add" element={<AdminSubsidyInfoAdd/>}/>*/}
                                {/*<Route path="/subsidy/info/:id" element={<AdminSubsidyInfo/>}/>*/}
                                <Route path="subsidy/*" element={<FeatureSubsidy/>}/>

                                <Route path="/user/list/:page?" element={<FeatureUser/>}/>
                                <Route path="/user/:id" element={null}/>

                                <Route path="/deal/*" element={<FeatureDeal/>}/>

                                <Route path="/antispam/" element={<FeatureAntispam/>}/>

                                <Route path="/news" element={<FeatureNews/>}/>

                                <Route path="*" element={<_404Page/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </MainBackground>
    );
}