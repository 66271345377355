import {Pagination} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {toNumber} from "@core/extension/Extensions";
import {useParams} from "react-router-dom";
import {UserList} from "./_component/UserList";
import {useAppContext} from "../../../../app/context/AppContextProvider";
import {UserModel} from "./_component/model/UserModel";
import {UserNetEntity} from "../../../../core-network/client/user/model/UserNetEntity";

export function UserListPage() {

    const context = useAppContext()

    const {page = 1} = useParams()
    const rootRef = useRef<HTMLDivElement>(null)
    const [currentPage, setCurrentPage] = useState<number>(Number(page));
    const [userList, setUserList] = useState<UserModel[]>([])
    const [loading, setLoading] = useState(true)

    function onChangePage(event: React.ChangeEvent<unknown>, value: number) {
        rootRef.current?.scrollIntoView()
        setCurrentPage(value);
    }

    useEffect(() => {
        setLoading(true)
        // context.networkProvider()
        //     .getUserClient()
        //     .getUserList(currentPage)
        //     .then(result => setUserList(toPresentation(result)))
        //     .catch(error => context.snackbar().showError(error.message))
        //     .finally(() => setLoading(false))
    }, [currentPage])

    return (
        <div
            className="flex flex-col"
            ref={rootRef}>
            {userList.length ?
                <div>
                    <UserList userList={userList}/>
                    <Pagination count={10} color="primary" onChange={onChangePage}/>
                </div>
                : null}
        </div>
    );
}

function toPresentation(netEntity: UserNetEntity[]): UserModel[] {
    return netEntity.map<UserModel>(user => new UserModel(user.id, user.phone, user.first_name, user.name, user.last_name))
}