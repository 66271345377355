import {List} from "@core/list/List";
import {SubsidyInfoModel} from "@admin/feature/subsidy/domain/model/SubsidyInfoModel";
import {OkvedModel} from "@admin/feature/subsidy/domain/model/OkvedModel";
import {AnswerVariantModel} from "@admin/feature/subsidy/domain/model/AnswerVariantModel";

export class SubsidyJuridicalDetailModel {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly isActive: boolean,
        public readonly isFavorite: boolean,
        public readonly info: List<SubsidyInfoModel>,
        public readonly okved: List<OkvedModel>,
        public readonly answerVariant: List<AnswerVariantModel>
    ) {
    }
}