import {FormEvent} from "react";
import {JsonUtils} from "@core/util/JsonUtils";

export class FormSerialize {

    public static serialize<T>(event: FormEvent<HTMLFormElement>): T | null {
        const child = event.currentTarget.childNodes
        return FormSerialize.createNodeFormTreeObject(child) as T
    }

    private static createNodeFormTreeObject(child: NodeListOf<ChildNode>): object | null {
        let result: Object | null = null

        child.forEach(node => {
            let nodeName = (node as HTMLInputElement).name

            if (nodeName != null && nodeName.match(/^.+\[\]/g)) {
                nodeName = nodeName.replace('[]', '')
                result = result ? result : {}
                // @ts-ignore
                result[nodeName] = this.createArrayFormTreeObject(node.childNodes)
                return;
            }

            let calcChildrenNodes = this.createNodeFormTreeObject(node.childNodes);
            if (calcChildrenNodes == null) {
                if (nodeName) {
                    result = result ? result : {}
                    let value = this.calcNodeValue(node)
                    if (JsonUtils.isJson(value)) {
                        value = JSON.parse(value)
                    }
                    // @ts-ignore
                    result[nodeName] = value
                }
                return;
            }
            if (nodeName != null) {
                result = result ? result : {}
                // @ts-ignore
                result[nodeName] = calcChildrenNodes
            } else {
                result = result ? result : {}
                result = {...result, ...calcChildrenNodes}
            }
        })
        return result
    }

    private static createArrayFormTreeObject(child: NodeListOf<ChildNode>): Array<any> {
        let array: Array<any> = []

        child.forEach(node => {
            let result: Object | undefined = undefined
            let nodeName = (node as HTMLInputElement).name

            let calcChildrenNodes = this.createNodeFormTreeObject(node.childNodes);
            if (calcChildrenNodes == null) {
                if (nodeName) {
                    result = result ? result : {}
                    result = this.calcNodeValue(node)
                }
                return;
            }
            if (nodeName != null) {
                result = result ? result : {}
                result = calcChildrenNodes
            } else {
                result = result ? result : {}
                result = {...result, ...calcChildrenNodes}
            }
            array.push(result)
        })
        return array
    }

    private static calcNodeValue(node: ChildNode): any {
        switch ((node as HTMLInputElement).type) {
            case 'text':
                return (node as HTMLInputElement).value
            case 'checkbox':
                return (node as HTMLInputElement).checked
            case 'select-multiple':
                return this.calcSelectOptionsValue(node as HTMLSelectElement)
            case 'select-one':
                if ((node as HTMLInputElement).getAttribute("input-type") === "number"){
                    return Number((node as HTMLSelectElement).value)
                }
                return (node as HTMLSelectElement).value
            case 'time':
                return (node as HTMLInputElement).value
            case 'file':
                return (node as HTMLInputElement).value
            case 'radio':
                return (node as HTMLInputElement).checked ? (node as HTMLInputElement).value : null
            case 'number':
                return Number((node as HTMLInputElement).value)
            default :
                return (node as HTMLInputElement).value
        }
    }

    private static calcSelectOptionsValue(selectElement: HTMLSelectElement): Array<any> {
        const valueAttr = selectElement.getAttribute("value-attr")

        return JSON.parse(valueAttr ? valueAttr : "[]")
    }
}