import {useAppContext} from "../../../../app/context/AppContextProvider";
import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Pagination} from "@mui/material";
import {DealModel} from "./_component/model/DealModel";
import {DealNetEntity} from "../../../../core-network/client/deal/model/DealNetEntity";
import {DealList} from "./_component/DealList";

export function AdminSubsidyDealListPage() {

    const context = useAppContext()

    const {page = 1} = useParams()
    const rootRef = useRef<HTMLDivElement>(null)
    const [currentPage, setCurrentPage] = useState<number>(Number(page));
    const [dealList, setDealList] = useState<DealModel[]>([])
    const [loading, setLoading] = useState(true)

    function onChangePage(event: React.ChangeEvent<unknown>, value: number) {
        rootRef.current?.scrollIntoView()
        setCurrentPage(value);
    }

    useEffect(() => {
        setLoading(true)
        // context.networkProvider()
        //     .getDealClient()
        //     .getDealList(currentPage)
        //     .then(result => setDealList(toPresentation(result)))
        //     .catch(error => context.snackbar().showError(error.message))
        //     .finally(() => setLoading(false))
    }, [currentPage])

    return (
        <div
            className="flex flex-col"
            ref={rootRef}>
            {dealList.length ?
                <div>
                    <DealList dealList={dealList}/>
                    <Pagination count={10} color="primary" onChange={onChangePage}/>
                </div>
                : null}
        </div>
    );
}

function toPresentation(netEntity: DealNetEntity[]): DealModel[] {
    return netEntity.map<DealModel>(user => new DealModel(user.id, user.title, user.description, user.uts_create))
}