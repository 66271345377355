import {AnswerNetworkModel} from "@admin/feature/subsidy/data/network/model/AnswerNetworkModel";
import {List} from "@core/list/List";

export class QuestionNetworkModel {

    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly isMultiple: boolean,
        public readonly answers: List<AnswerNetworkModel>
    ) {
    }
}