import {DetailedHTMLProps, HTMLAttributes} from "react";
import {DealModel} from "./model/DealModel";
import {DealItem} from "./DealItem";

interface DealListProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    dealList: DealModel[]
}

export function DealList(props: DealListProps) {

    const {dealList, ...divProps} = props

    return (
        <div {...divProps}>
            {
                dealList.map((deal) => {
                    return <DealItem deal={deal}/>
                })
            }
        </div>
    );
}