import {CompanyPhoneNetworkModel} from "../../../../core-network/client/phone/model/CompanyPhoneNetworkModel";
import React from "react";
import {ButtonTrashDelete} from "@core/ui/button/ButtonTrashDelete";

interface PhoneItemProps {
    data: CompanyPhoneNetworkModel
    handleDeletePhone: (idNumber: number) => void
}
export function PhoneItem(props:PhoneItemProps) {

    return (
            <div className="flex flex-row items-center p-2 rounded-lg shadow-lg border-2 border-gray-100">
                <div className="grow">{props.data.phone}</div>
                <div className="flex-none">
                    <ButtonTrashDelete onClick={()=>{props.handleDeletePhone(props.data.id)}}/>
                </div>
            </div>
    );
}