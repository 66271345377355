export class Logger {
    static debug(message?: any) {
        if (this.isDev()) {
            let date = new Date()
            let time = date.getDate()+"/"+date.getMonth()+"/"+date.getFullYear()+" "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()
            console.log(time,message)
        }
    }

    private static isDev() {
        return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    }
}