import React from "react";
import {JuridicalTab} from "@admin/feature/subsidy/ui/tab/JuridicalTab";
import {Route, Routes} from "react-router-dom";
import {JuridicalList} from "@admin/feature/subsidy/ui/list/JuridicalList";
import {JuridicalDetailPage} from "@admin/feature/subsidy/ui/page/JuridicalDetailPage";

export function JuridicalPage() {

    return (
        <div className="flex flex-col h-full">
            <JuridicalTab/>
            <Routes>
                <Route path="/*" element={<JuridicalList/>}/>
            </Routes>
        </div>
    )
}