import React from "react";
import {_404Page} from "@core/ui/pages/404/_404Page";

export function FeatureNews() {

    return (
        <div>
            Новости
            <_404Page/>
        </div>
    )
}