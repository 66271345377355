import {NetworkProvider} from "./NetworkProvider";
import {AuthClientImpl} from "@anon/core/network/client/auth/AuthClientImpl";
import {NetworkBuilder} from "@core/network/builder/NetworkBuilder";
import {NetworkBuilderClient} from "@anon/core/network/builder/NetworkBuilderClient";
import {AuthClient} from "@anon/core/network/client/auth/AuthClient";

export class NetworkProviderImpl implements NetworkProvider {

    constructor(
        private readonly builder: NetworkBuilder
    ) {
    }

    static init = (): NetworkProvider =>
        new NetworkProviderImpl(
            new NetworkBuilderClient()
        )

    getAuthClient(): AuthClient {
        return this.builder.create(AuthClientImpl)
    }

}