import {ButtonProps} from "@core/ui/button/button-props";
import {IconClose} from "@core/ui/icon/IconClose";

export function ButtonRemove(props: ButtonProps) {

    function renderButton() {

        return (
            <div className="flex flex-row bg-red-500 p-2 cursor-pointer rounded-lg text-white justify-center select-none
        items-center hover:bg-red-600 active:bg-red-700"
                 onClick={props.onClick}><IconClose/>
            </div>
        )
    }

    return (
        props.className ? <div className={props.className}>{renderButton()}</div>
            : renderButton()
    )
}