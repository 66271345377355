import {UpdateSubsidyJuridicalRequest} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalRequest";
import {List} from "@core/list/List";
import {InfoSubsidyRequest} from "@admin/core/network/client/subsidy/model/request/InfoSubsidyRequest";

export class UpdateSubsidyJuridicalInfoRequest extends UpdateSubsidyJuridicalRequest {

    constructor(
        readonly id: number,
        readonly info: List<InfoSubsidyRequest>,
    ) {
        super(id);
    }
}