import {InfoTypeNetworkModel} from "@admin/feature/subsidy/data/network/model/InfoTypeNetworkModel";

export class InfoNetworkModel {

    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly type: InfoTypeNetworkModel,
    ) {
    }
}