import {useEffect, useState} from "react";
import {AnswerModel} from "@admin/feature/subsidy/domain/model/AnswerModel";
import {QuestionModel} from "@admin/feature/subsidy/domain/model/QuestionModel";
import {SubsidyInteractorImpl} from "@admin/feature/subsidy/domain/SubsidyInteractorImpl";
import {cloneDeep} from "lodash";
import {useAppContext} from "@app/context/AppContextProvider";
import {ProgressBar} from "@core/ui/progress/ProgressBar";
import {FormFieldSet} from "@core/form/fieldset/FormFieldSet";
import {ButtonRemove} from "@core/ui/button/ButtonRemove";
import {ButtonPrimary} from "@core/ui/button/ButtonPrimary";
import {List} from "@core/list/List";

interface VariantQuestion {
    isShow: boolean
    questions: Array<SelectQuestion>
    isAddQuestion: boolean
}

interface SelectQuestion {
    selectAr: Array<{ question: QuestionModel, isDisable: boolean }>
    select?: {
        question: QuestionModel,
        isAddAnswer: boolean,
        answers: Array<{ selectAnswer?: AnswerModel }>,
        answersAr: Array<{ answer: AnswerModel, isDisable: boolean }>
    }
}

interface FormSelectQuestionVariantProps {
    defValue?: List<{ id: number, question: List<{ id: number, answer: List<{ id: number }> }> }>
}

export function FormSelectQuestionVariant(props: FormSelectQuestionVariantProps) {

    const context = useAppContext()
    const interactor = SubsidyInteractorImpl.init()

    const [variantQuestion, setVariantQuestion] = useState<Array<VariantQuestion>>([])
    const [totalQuestion, setTotalQuestion] = useState<Array<QuestionModel>>([])
    const [loading, setLoading] = useState(true)

    function addQuestion(index: number) {

        let newVariantQuestion = cloneDeep(variantQuestion)
        const questionArr = newVariantQuestion[index].questions.map(it => it.select?.question)

        newVariantQuestion[index].isAddQuestion = newVariantQuestion[index].questions.length + 1 != totalQuestion.length

        newVariantQuestion[index].questions =
            [...newVariantQuestion[index].questions, {
                selectAr: totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                    return {question: it, isDisable: questionArr.find(it1 => it1?.id === it.id) !== undefined}
                }),
                select: undefined,
            }]

        setVariantQuestion([...newVariantQuestion])
    }

    function addAnswer(indexVariant: number, index: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        newVariantQuestion[indexVariant].questions[index].select!!.answers =
            [...newVariantQuestion[indexVariant].questions[index].select!!.answers, {selectAnswer: undefined}]
        newVariantQuestion[indexVariant].questions[index].select!!.isAddAnswer =
            newVariantQuestion[indexVariant].questions[index].select!!.answersAr.length !==
            newVariantQuestion[indexVariant].questions[index].select!!.answers.length
        setVariantQuestion(newVariantQuestion)
    }

    function onChangeAnswer(answer: AnswerModel, indexVariant: number, indexQuestion: number, indexAnswer: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)

        newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answers[indexAnswer].selectAnswer = answer
        newVariantQuestion[indexVariant].questions[indexQuestion].select!!.answersAr.find(it => it.answer.id == answer.id)!!.isDisable = true

        setVariantQuestion(newVariantQuestion)
    }

    function onChangeSelectQuestion(question: QuestionModel, indexVariant: number, index: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        newVariantQuestion[indexVariant].questions[index].select =
            {
                question: question,
                isAddAnswer: true,
                answers: [{selectAnswer: undefined}],
                answersAr: question.answer.map<{ answer: AnswerModel, isDisable: boolean }>(it => {
                    return {answer: it, isDisable: false}
                })

            }

        const questionArr = newVariantQuestion[indexVariant].questions.map(it => it.select?.question)

        newVariantQuestion[indexVariant].questions.map<SelectQuestion>(it => {
            it.selectAr = totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                return {question: it, isDisable: questionArr.find(it1 => it1?.id === it.id) !== undefined}
            })
            return it
        })

        setVariantQuestion(newVariantQuestion)
    }

    function addVariantQuestion() {
        let newVariantQuestion = cloneDeep(variantQuestion)
        setVariantQuestion([...newVariantQuestion,
            {
                isShow: true,
                questions: [{
                    selectAr: totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                        return {question: it, isDisable: false}
                    }),
                    select: undefined,
                }],
                isAddQuestion: true
            }])
    }

    function removeVariant(index: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        newVariantQuestion.splice(index, 1)
        setVariantQuestion(newVariantQuestion)
    }

    function removeQuestion(indexVariant: number, index: number) {
        let newVariantQuestion = cloneDeep(variantQuestion)
        newVariantQuestion[indexVariant].questions.splice(index, 1)
        const questionArr = newVariantQuestion[indexVariant].questions.map(it => it.select?.question)
        newVariantQuestion[indexVariant].questions.map<SelectQuestion>(it => {
            it.selectAr = totalQuestion.map<{ question: QuestionModel, isDisable: boolean }>(it => {
                return {question: it, isDisable: questionArr.find(it1 => it1?.id === it.id) !== undefined}
            })
            return it
        })

        newVariantQuestion[indexVariant].questions[index].select!!.isAddAnswer =
            newVariantQuestion[indexVariant].questions[index].select!!.answersAr.length !==
            newVariantQuestion[indexVariant].questions[index].select!!.answers.length
        setVariantQuestion(newVariantQuestion)
    }

    useEffect(() => {
        interactor.getQuestionJuridicalList()
            .then(it => setTotalQuestion(it))
            .catch(context.snackbar.showError)
            .finally(() => setLoading(false))
    }, []);

    useEffect(() => {
    }, [variantQuestion]);

    return (
        <div className="flex flex-col bg-white">
            <div className="w-full text-center border-b">Вопросы и ответы</div>
            <div className="flex flex-col bg-white">
                {loading ? <div className="self-center"><ProgressBar/></div> :
                    <div className="mt-2">
                        <FormFieldSet className="flex flex-col gap-y-2"
                                      name="questionVariant[]">
                            {
                                variantQuestion.map((varQuestion, indexVariant) => {
                                    return (
                                        <div>
                                            <FormFieldSet className="flex flex-col gap-y-2 bg-gray-100 rounded-lg p-2"
                                                          key={indexVariant}
                                                          name={`question[]`}>
                                                {varQuestion.questions.map((question, indexQuestion) => {
                                                    return (
                                                        <div className="flex flex-row gap-x-2"
                                                             key={indexQuestion}>
                                                            <div
                                                                className="flex flec-row bg-primary-200 p-2 gap-x-2 rounded-lg">
                                                                <select
                                                                    name="id"
                                                                    typeof="number"
                                                                    onChange={event =>
                                                                        onChangeSelectQuestion(totalQuestion.find(it => event.currentTarget.value == it.id.toString())!!, indexVariant, indexQuestion)
                                                                    }
                                                                    input-type="number"
                                                                >
                                                                    <option value="-1" key={-1} selected disabled>None
                                                                    </option>
                                                                    {
                                                                        question.selectAr.map(select => {
                                                                            return (
                                                                                <option key={select.question.id}
                                                                                        value={select.question.id}
                                                                                        disabled={select.isDisable}>{select.question.title}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                <ButtonRemove/>
                                                            </div>
                                                            <FormFieldSet className="flex flex-row gap-x-1"
                                                                          name="answer[]">
                                                                {
                                                                    question.select?.answers.map((it, index) => {
                                                                        return (
                                                                            <div className="flex flex-row">
                                                                                <select
                                                                                    key={index}
                                                                                    name="id"
                                                                                    typeof="number"
                                                                                    input-type="number"
                                                                                    onChange={event => onChangeAnswer(
                                                                                        question.select!!.answersAr.find(it => event.currentTarget.value == it.answer.id.toString())!!.answer,
                                                                                        indexVariant,
                                                                                        indexQuestion,
                                                                                        index)}>
                                                                                    <option value="-1" key={-1} selected
                                                                                            disabled>None
                                                                                    </option>
                                                                                    {

                                                                                        question.select?.answersAr.map(it => {
                                                                                            return <option
                                                                                                key={it.answer.id}
                                                                                                value={it.answer.id}
                                                                                                disabled={it.isDisable}>{it.answer.title}</option>
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                                <ButtonRemove/>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </FormFieldSet>
                                                            {
                                                                question.select?.isAddAnswer ?
                                                                    <div className="flex justify-center items-center bg-green-300 rounded-full w-6 h-6 cursor-pointer
                                            hover:bg-green-400
                                            active:bg-green-500"
                                                                         onClick={_ => addAnswer(indexVariant, indexQuestion)}>+</div>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                                {varQuestion.isAddQuestion ?
                                                    <ButtonPrimary className="flex p-2 w-full justify-start"
                                                                   onClick={() => addQuestion(indexVariant)}
                                                                   label="Добавить вопрос"/>
                                                    : <></>}
                                            </FormFieldSet>
                                        </div>
                                    )
                                })
                            }
                        </FormFieldSet>
                        <ButtonPrimary className="flex p-2 w-full justify-center" onClick={addVariantQuestion}
                                       label="Добавить вариант ответов"/>
                    </div>}
            </div>
        </div>
    )
}