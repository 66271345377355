import React, {RefObject} from "react";
import {FormFieldSetProps, FormFieldSetState} from "./form-input-settings";

type Props = Readonly<FormFieldSetProps>
type State = Readonly<FormFieldSetState>

export class FormFieldSet extends React.Component<Props, State> {

    public static readonly defaultProps: Props = {}

    public readonly state: State = {}

    private readonly ref: RefObject<HTMLFieldSetElement> = React.createRef<HTMLFieldSetElement>()

    render() {
        return <fieldset ref={this.ref} {...this.props}/>
    };
}