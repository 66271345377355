import React, {ChangeEvent, useEffect, useState} from "react";
import {ProgressBar} from "@core/ui/progress/ProgressBar";
import {NetworkProviderImpl} from "../../../core-network/NetworkProviderImpl";
import {CompanyPhoneNetworkModel} from "../../../core-network/client/phone/model/CompanyPhoneNetworkModel";
import {ButtonFloating} from "@core/ui/button/ButtonFloating";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {useAppContext} from "../../../app/context/AppContextProvider";
import {IconPlus} from "@core/ui/icon/IconPlus";
import {PhoneList} from "./_component/PhoneList";

export function FeatureAntispam() {

    const appContext = useAppContext()
    const networkProvider = new NetworkProviderImpl()

    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [phone, setPhone] = useState<CompanyPhoneNetworkModel[]>([])
    const [number, setNumber] = useState<string>("+7")
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    const handleClickOpen = () => {
        setNumber("+7")
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleAddPhone = () => {
        if (number.length === 12) {
            networkProvider.getCompanyClient().setPhone(number.replace("+", ""),
                () => {
                    setNumber("+7")
                    appContext.snackbar.show("Номер успешно добавлен")
                    setOpen(false)
                    updateList()
                },
                () => {
                })
        } else {
            setErrorMessage("Введите корректный номер")
            setIsError(true)
        }
    }

    const handleChangeNumber = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isError) {
            setErrorMessage("")
            setIsError(false)
        }
        const number = '+' + event.target.value.replace(/\D/g, '');
        if (number.length > 1 && number.length < 13) {
            setNumber(number)
        }
    }

    const handleDeletePhone = (idPhone: number) => {
        // networkProvider.getCompanyClient().deletePhone(idPhone,
        //     (_) => {
        //         appContext.snackbar().show("Номер успешно удален")
        //         updateList()
        //         setIsLoading(false)
        //     }, (error) => {
        //         appContext.snackbar().showError(error.message)
        //     })
    }

    useEffect(() => {
        updateList()
    }, [])

    const updateList = () => {
        // networkProvider.getCompanyClient().getPhone(
        //     (response) => {
        //         setPhone(response)
        //         setIsLoading(false)
        //     }, (error) => {
        //         setIsLoading(false)
        //         appContext.snackbar().showError(error.message)
        //     })
    }

    return (
        <div>
            {
                isLoading ?
                    <ProgressBar/>
                    : phone.length ?
                        <PhoneList data={phone} handleDeletePhone={handleDeletePhone}/>
                        :
                        <div>
                            Ничего нету :(
                        </div>
            }
            <ButtonFloating text={"Добавить"} onClick={handleClickOpen} icon={<IconPlus/>}/>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Добавить номер</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите номер телефона для того чтобы добавить его в список
                    </DialogContentText>
                    <TextField
                        error={isError}
                        helperText={errorMessage}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Телефон"
                        value={number}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChangeNumber}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Назад</Button>
                    <Button onClick={handleAddPhone}>Добавить</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}