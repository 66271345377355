export function toString(value: any): string {
    return String(value);
}

export function toNumber(value: any): number {
    return Number(value);
}

export function isNumber(value: any): boolean {
    try {
        Number(value)
    } catch (error: any) {
        return false
    }
    return true
}

export function isValidEmail(value: string): boolean {
    return !!value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function isValidPhone(value: string): boolean {
    return !!value.match(/^\d+$/)
}

export function foreach<T>(callback: (item: T) => boolean, array: Array<T>) {
    for (let i = 0; i < array.length; i++) {
        if (!callback(array[i])) {
            break
        }
    }
}

export function isEmpty(data: any | Array<any> | string | undefined | null): boolean {
    if (data == undefined) {
        return true
    }
    return data.length < 1;
}

export function copy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
}
