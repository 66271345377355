import React, {DetailedHTMLProps, HTMLAttributes} from "react";
import {UserModel} from "./model/UserModel";
import {Link} from "react-router-dom";

interface UserItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    user: UserModel
}

export function UserItem(props: UserItemProps) {

    const {user, ...divProps} = props

    return (
        <div {...divProps}>
            <Link to={`#`} className="flex flex-row m-1 gap-x-2 bg-white shadow-lg rounded-lg p-4
                hover:bg-blue-300
                active:bg-blue-200
                cursor-pointer"
                  id={String(user.id)}>
                {/*<div className="flex-none w-14 h-14">{subsidy.id}</div>*/}
                <div className="flex">{user.firstName}</div>
                <div className="flex">{user.name}</div>
                <div className="flex">{user.lastName}</div>
            </Link>
        </div>
    );
}