import {CompanyPhoneNetworkModel} from "../../../../core-network/client/phone/model/CompanyPhoneNetworkModel";
import React from "react";
import {SubsidyItem} from "@admin/feature/subsidy/list/component/SubsidyItem";
import {PhoneItem} from "./PhoneItem";

interface PhoneListProps {
    data: Array<CompanyPhoneNetworkModel>
    handleDeletePhone: (idNumber: number) => void
}

export function PhoneList(props: PhoneListProps) {

    return (
        <div className="flex flex-col gap-y-3">

            {"Номер телефонов Нашей компании"}
            {
                props.data.length ? props.data.map(phone =>
                    (
                        <div key={phone.id}>
                            <PhoneItem data={phone} handleDeletePhone={props.handleDeletePhone}/>
                        </div>
                    )
                ) : <h4>Ничего нету :(</h4>
            }
        </div>
    );
}