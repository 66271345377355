import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {InputProps} from "@core/form/form-settings";
import "./form-input-reach-text.css";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";


interface FormInputReachTextProps extends InputProps<string> {
    label?: string
    icon?: ReactNode
    iconAnchor?: 'left' | 'right'
    description?: string
}

export function FormInputReachText(props: FormInputReachTextProps) {

    const [editorState, setEditorState] = useState<EditorState>();


    function onEditorStateChange(editorState: EditorState) {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        setEditorState(editorState)
    }

    function getHTML(editor?: EditorState): string {
        return editor ? draftToHtml(convertToRaw(editor.getCurrentContent())) : ""
    }

    useEffect(() => {
        if (props.value !== undefined) {
            const contentBlock = convertFromHTML(props.value)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            const editorState = EditorState.createWithContent(contentState)
            setEditorState(editorState)
        }
    }, [props.value]);

    function renderEditor() {
        return (
            <>
                <input
                    name={props.name}
                    value={getHTML(editorState)}
                    readOnly={true}
                    hidden/>
                <Editor
                    editorState={editorState}
                    editorStyle={{border: "1px solid rgba(200, 200, 200, 0.3)",padding:"20px"}}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                />
            </>
        )
    }

    return (
        props.className ?
            <div className={props.className}>
                {renderEditor()}
            </div> :
            renderEditor()
    )
}