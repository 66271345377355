import {UpdateSubsidyJuridicalModel} from "@admin/feature/subsidy/domain/model/UpdateSubsidyJuridicalModel";
import {List} from "@core/list/List";
import {InfoModel} from "@admin/feature/subsidy/domain/model/InfoModel";

export class UpdateSubsidyJuridicalInfoModel extends UpdateSubsidyJuridicalModel {

    constructor(
        readonly id: number,
        readonly info: List<{ id: number, value: string }>,
    ) {
        super(id);
    }
}