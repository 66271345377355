import {ButtonProps} from "@core/ui/button/button-props";

interface ButtonPrimaryProps extends ButtonProps {

}

export function ButtonPrimary(props: ButtonPrimaryProps) {
    function renderButton() {
        return (
            <div className="flex flex-row bg-primary-400 p-2 cursor-pointer rounded-lg text-white justify-center select-none
        hover:bg-primary-500
        active:bg-primary-600"
                 onClick={props.onClick}>{props.label}</div>
        )
    }

    return (
        props.className ? <div className={props.className}>{renderButton()}</div> : renderButton()
    )
}