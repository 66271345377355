import {API_KEY} from "../../../config.js";
import {BaseClient} from "../../builder/BaseClient";
import {UserClient} from "./UserClient";
import {UserNetEntity} from "./model/UserNetEntity";
import {UserStorage} from "@core/storage/user/UserStorage";

export class UserClientImpl extends BaseClient implements UserClient {
    protected userStorage: UserStorage

    constructor(userStorage: UserStorage) {
        super(
            new Headers({
                'Host': 'https://www.kosmosmarketing.ru',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userStorage.getToken()?.access ?? API_KEY}`,
            })
        )
        this.userStorage = userStorage
    }

    getUser(idUser: number): Promise<UserNetEntity> {
        return this.get<UserNetEntity>(`/user/${idUser}`);
    }

    getUserList(page: number): Promise<UserNetEntity[]> {
        return this.get<UserNetEntity[]>(`/user/list/${page}`);
    }

}