import {z} from "zod";

export const SubsidyPhysicalFormSchema = z.object({
    title: z.string(),
    isActive: z.boolean(),
    isFavorite: z.boolean(),
    questionVariant: z.object({
        question: z.object({
            id: z.number(),
            answer: z.object({
                id: z.number()
            }).array()
        }).array()
    }).array(),
    info: z.object({
        id: z.number(),
        value: z.string()
    }).array(),
    okved: z.object({
        id: z.string(),
        name: z.string()
    }).array()
})

export type AddSubsidyPhysical = z.infer<typeof SubsidyPhysicalFormSchema>