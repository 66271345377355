import {LogoIcon} from "@core/ui/image/LogoIcon";
import {LoginInputField} from "@anon/core/ui/inputField/LoginInputField";
import {PasswordInputField} from "@anon/core/ui/inputField/PasswordInputField";
import {CheckBox} from "@core/ui/checkbox/CheckBox";
import {ButtonPrimary} from "@core/ui/button/ButtonPrimary";
import {TextLink} from "@core/ui/text/TextLink";
import React, {useState} from "react";
import {AuthInteractor} from "@anon/feature/auth/domain/AuthInteractor";
import {AuthInteractorImpl} from "@anon/feature/auth/domain/AuthInteractorImpl";
import {useAppContext} from "../../../../app/context/AppContextProvider";
import {Page, Router} from "@anon/core/router/Router";

export function SignInPage(){

    const context = useAppContext()
    const interactor: AuthInteractor = AuthInteractorImpl.init()
    const router:Router = new Router()

    const _TITLE = "Войдите в свой аккаунт"
    const _REMEMBER_ME = "Запомнить меня"
    const _FORGOT_PASSWORD = "Забыли свой пароль?"
    const _SIGN_IN = "Войти"

    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')

    function auth() {
        interactor.auth(phone,password)
            .catch(error=>context.snackbar.showError(error))
            .then(it=>router.to(Page.Root))

    }
    return (
        <div className="relative px-6 pt-10 pb-8
        sm:bg-white sm:shadow-xl sm:ring-1 sm:ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
            <div className="flex flex-col py-4 px-4sm:px-6 lg:px-8 items-center justify-center min-h-full">
                <div className="m-5">
                    <LogoIcon height={128} width={128}/>
                </div>
                <div>
                    <h2 className="mb-6 text-center text-3xl font-bold tracking-tight text-gray-900">{_TITLE}</h2>
                </div>
                <div className="max-w-sm w-full">
                    <LoginInputField onChange={(value) => {
                        setPhone(value)
                    }}/>
                </div>
                <div className="max-w-sm w-full">
                    <PasswordInputField onChange={(value) => {
                        setPassword(value)
                    }}/>
                </div>
                <div className="mt-5">
                    <CheckBox text={_REMEMBER_ME} />
                </div>
                <div className="mt-5 w-full">
                    <ButtonPrimary label={_SIGN_IN} onClick={() => {
                        auth()
                    }}/>
                </div>
                <div className="mt-2">
                    <TextLink text={_FORGOT_PASSWORD} onClickListener={void function () {}}/>
                </div>
            </div>
        </div>
    )
}