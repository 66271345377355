import {TabNavigation} from "@admin/feature/subsidy/ui/TabNavigation";
import React from "react";

export function JuridicalTab() {

    return (
        <div className="flex flex-row">
            <TabNavigation to={""} text={"Список субсидий"}/>
            {/*<TabNavigation to={"question/list/"} text={"Структура вопросов"}/>*/}
            {/*<TabNavigation to={"/subsidy/list"} text={"Список субсидий"}/>*/}
            {/*<TabNavigation to={"test/"} text={"Пройти тест"}/>*/}
            {/*<TabNavigation to={"info/list/"} text={"Список доп.иформации"}/>*/}
            {/*<TabNavigation to={"info/type/"} text={"Список типов доп.иформации"}/>*/}
        </div>
    )
}