import {SignInResponse} from "@anon/core/network/client/auth/model/SignInResponse";
import {SignInNetworkModel} from "@anon/feature/auth/data/network/model/SignInNetworkModel";
import {UserResponse} from "@anon/core/network/client/auth/model/UserResponse";
import {UserNetworkModel} from "@anon/feature/auth/data/network/model/UserNetworkModel";
import {TokenResponse} from "@anon/core/network/client/auth/model/TokenResponse";
import {TokenNetworkModel} from "@anon/feature/auth/data/network/model/TokenNetworkModel";
import {UserRole} from "@anon/core/network/client/auth/model/UserRole";
import {ProfileRole} from "@core/storage/user/model/ProfileRole";
import {Profile} from "@core/storage/user/model/Profile";
import {UserToken} from "@core/storage/user/model/UserToken";

export function signInResponse_toNetworkModel(response: SignInResponse): SignInNetworkModel {
    return new SignInNetworkModel(
        userResponse_toNetworkModel(response.user),
        tokenResponse_toNetworkModel(response.token),
    )
}

export function userResponse_toNetworkModel(response: UserResponse): UserNetworkModel {
    return new UserNetworkModel(
        response.id,
        response.name,
        response.surname,
        response.role,
        response.patronymic,
        response.avatar_url
    )
}

export function tokenResponse_toNetworkModel(response: TokenResponse): TokenNetworkModel {
    return new TokenNetworkModel(
        response.access,
        response.refresh
    )
}

export function tokenNetworkModel_toProfile(token: TokenNetworkModel): UserToken {
    return new UserToken(
        token.access,
        token.refresh,
    )
}

export function userNetworkModel_toProfile(user: UserNetworkModel): Profile {
    return new Profile(
        user.name,
        user.surname,
        userRole_toProfileRole(user.role),
        user.patronymic,
        user.avatarUrl
    )
}

export function userRole_toProfileRole(userRole: UserRole): ProfileRole {
    switch (userRole) {
        case UserRole.ADMIN:
            return ProfileRole.ADMIN;
        case UserRole.CLIENT:
            return ProfileRole.CLIENT;
        case UserRole.LAWYER:
            return ProfileRole.LAWYER;
        case UserRole.CONTENT_MANAGER:
            return ProfileRole.CONTENT_MANAGER;
        case UserRole.BOT:
            return ProfileRole.BOT;

    }
}