import {ButtonHTMLAttributes, DetailedHTMLProps} from "react";

interface ButtonFloating_v2Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    icon?: JSX.Element
}

export function ButtonFloating_v2(props: ButtonFloating_v2Props) {

    const {icon, ...buttonProps} = props

    return (
        <button
            className="fixed z-20 bottom-10 right-10 bg-primary-400 w-16 h-16 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl
            hover:bg-primary-300 active:bg-primary-600"
            {...buttonProps}>
            {icon}
        </button>
    );
}