import {DetailedHTMLProps, InputHTMLAttributes} from "react";

interface CheckBoxProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    text?: string
}

export function CheckBox(props: CheckBoxProps) {

    const {text, ...inputProps} = props
    return (
        <div className="flex items-center">
            <input type="checkbox"
                   className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                   {...inputProps}/>
            <label className="ml-2 block text-sm text-gray-900">{text}</label>
        </div>
    );
}