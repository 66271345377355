import {UpdateSubsidyJuridicalRequest} from "@admin/core/network/client/subsidy/model/request/UpdateSubsidyJuridicalRequest";
import {List} from "@core/list/List";

export class UpdateSubsidyJuridicalOkvedRequest extends UpdateSubsidyJuridicalRequest {

    constructor(
        readonly id: number,
        readonly okved: List<string>,
    ) {
        super(id);
    }
}